import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HelpersServices } from "src/app/helpers/helpers.services";
import {
	ClassNameType,
	LabelToggleInputEditEmitterType,
} from "../../molecules/label-toggle-input-edit/label-toggle-input-edit.component";
import * as moment from "moment";

@Component({
	selector: "app-input-date-picker",
	templateUrl: "./input-date-picker.component.html",
	styleUrls: ["./input-date-picker.component.scss"],
})
export class InputDatePickerComponent implements OnInit {
	@Input() inputName?: string;
	@Input() inputId?: string | number;
	@Input() value?: string;
	@Input() placeholder?: string;
	@Input() disabled?: boolean;
	@Input() className?: string | ClassNameType;

	@Output() action = new EventEmitter<LabelToggleInputEditEmitterType>();

	originalValue: Date;
	dateValue: Date;
	bgConfig = { isAnimated: true, dateInputFormat: 'DD/MM/YYYY', language:'es-ES'  };
	/*bsConfig = Object.assign(
		{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY', language:'es' },
		{ locale: 'es' }
	);*/

	constructor(private helpers: HelpersServices) {}

	ngOnInit() {
		this.value = this.value || "";
		this.dateValue = this.value ? moment(this.value).toDate() : null;
		this.originalValue = this.dateValue;
		this.inputId = this.helpers.guid();
		this.inputName = this.inputName || "input-date";
	}

	onAction(answer: boolean) {
		if (answer) {
			const selectedDate = this.dateValue
				? moment(this.dateValue).format("YYYY-MM-DD")
				: "";
			this.originalValue = this.dateValue;
			this.action.emit({
				inputName: this.inputName,
				value: selectedDate,
			});
		} else {
			this.dateValue = this.originalValue;
			this.action.emit({
				inputName: null,
				value: null,
			});
		}
	}
}
