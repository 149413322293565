import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { ProjectFiltersInterface, ProjectInputsFilterData } from 'src/app/interfaces/filters-inputs.interface';
import { GetProjectListParams } from 'src/app/interfaces/projects.interfaces';
import { ActivatedRoute } from '@angular/router';
import { HelpersServices } from '../../helpers/helpers.services';
import { Paginator } from 'src/app/interfaces/paginator.interface';

@Component({
	selector: 'app-projects-filter',
	templateUrl: './projects-filter.component.html',
	styleUrls: ['./projects-filter.component.scss']
})
export class ProjectsFilterComponent implements OnInit {
	@Input() expanded: boolean;
	@Input() paginator: Paginator;
	@Input() isLoading: boolean;
	@Input() tooltip;

	@Output() filtering = new EventEmitter<ProjectFiltersInterface>();
	@Output() expandedChange = new EventEmitter<boolean>();

	localUrl = '/projects/list';
	params: GetProjectListParams;
	filterIsOpen = true;
	filters: ProjectFiltersInterface = {
		inputsFilterData: { },
		inputsFilterParams: { },
		inputChanged: null,
		searchTexts: [],
	};
	primaryParametersNames: GetProjectListParams = {
		buildingId: null,
		managerId: null,
		engineerId: null,
		techLeaderId: null,
		tiLeaderId: null,
		businessExecutiveId: null,
		salesExecutiveId: null,
		edacId: null,
		managementType: null,
		onTimeStatus: null,
		isSanctioned: null,
		closureProjection: null,
		typeId: null,
	};
	secondaryParametersNames: GetProjectListParams = {
		search: null,
		statusId: null,
	};

	constructor(
		private route: ActivatedRoute,
		private helper: HelpersServices,
	) {
		this.params = { ...this.route.snapshot.queryParams };
	}

	getPreselectedParameters(current: GetProjectListParams): GetProjectListParams {
		for (const name in current) {
			if (Object.prototype.hasOwnProperty.call(current, name)) {
				const value = this.params[name];
				current[name] = value;
			}
		}
		return current;
	}

	ngOnInit() {
		this.primaryParametersNames = this.getPreselectedParameters(this.primaryParametersNames);
		this.secondaryParametersNames = this.getPreselectedParameters(this.secondaryParametersNames);
	}

	onFiltering(filters: ProjectFiltersInterface) {
		filters.inputsFilterParams = {
			...this.filters.inputsFilterParams,
			...filters.inputsFilterParams,
		};
		filters.inputsFilterList = {
			...this.filters.inputsFilterList,
			...filters.inputsFilterList,
		};
		filters.inputsFilterData = {
			...this.filters.inputsFilterData,
			...filters.inputsFilterData,
		};
		this.filters = {
			...this.filters,
			...filters,
		};
		this.filtering.emit(this.filters);
		this.helper.updateUrlParameters(this.localUrl, {
			...this.params,
			...filters.inputsFilterParams
		});
	}

	onExpandColapseAll(expanded: boolean) {
		this.expandedChange.emit(expanded);
	}

}
