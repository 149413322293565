import { Injectable } from '@angular/core';
import * as IO from 'socket.io-client';
import { environment } from '../../environments/environment';
import { HelpersServices } from './helpers.services';

export interface PermissionUpdate {
	userId?: number;
	type: 'role' | 'group' | 'user';
	userAffected?: number | number[];
	roleAffected?: number | number[];
	groupAffected?: number | number[];
}

@Injectable({
	providedIn: 'root',
})
export class SocketioService {
	socket: any;

	constructor(private helpers: HelpersServices) { }

	connect() {
		this.socket = IO(environment.socketUrl);
	}

	on(channel: string, callback: (data: any) => any): void {
		this.socket.on(channel, callback);
	}

	updateUsersPermissions(data: PermissionUpdate): void {
		const userAuth = this.helpers.getDecodedUser();

		if (userAuth) {
			const params = {
				...data,
				userId: parseInt(userAuth.user.userId, 10),
			};

			this.socket.emit('permissionsChange', params);
		}
	}
}
