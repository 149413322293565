import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';

export type InputValue = string | number | boolean | FilterConcept[] | FilterConcept;
@Component({
	selector: 'app-fields-label',
	templateUrl: './fields-label.component.html',
	styleUrls: ['./fields-label.component.scss']
})
export class FieldsLabelComponent implements OnInit {
	@Input() label: InputValue;
	@Input() labelDate?: InputValue;
	@Input() emptyText = '';
	@Input() appendText = '';
	@Input() className: string | { [propName: string]: boolean };
	@Input() icon: string;

	constructor() { }

	ngOnInit() {
		this.setCurrentValue();
		this.icon = this.icon || 'fa-edit';
		this.emptyText = !this.emptyText ? 'app.emptyData' : this.emptyText;

		if (typeof(this.label) === 'string') {
			this.label = (this.label.length > 165) ? this.label.substring(0, 165) + '...' : this.label;
		}
	}

	setCurrentValue() {
		if (this.label && typeof this.label === 'object') {
			const value = this.label as FilterConcept;

			this.label = value.ds_name || this.emptyText || '';
		} else {
			this.label = this.label || this.emptyText || '';
		}

	}

}
