import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FilterConcept } from '../../../interfaces/projects.interfaces';
import { HelpersServices } from '../../../helpers/helpers.services';
import { LabelToggleInputEditEmitterType } from '../../molecules/label-toggle-input-edit/label-toggle-input-edit.component';
import { HasDisableType } from 'src/app/interfaces/directives.interface';

@Component({
	selector: 'app-select-edit',
	templateUrl: './select-edit.component.html',
	styleUrls: ['./select-edit.component.scss']
})
export class SelectEditComponent implements OnInit, OnChanges {
	@Input() allowClear: boolean;
	@Input() inputName?: string;
	@Input() items: FilterConcept[];
	@Input() multiple: boolean;
	@Input() placeholder = 'Seleccione...';
	@Input() value: FilterConcept[] | FilterConcept;
	@Input() disabled: boolean;

	@Output() submit = new EventEmitter<LabelToggleInputEditEmitterType>();

	id: string;
	selected: string[] | string;
	originalValue: string[] | string;

	constructor(
		private helpers: HelpersServices
	) { }

	ngOnInit() {
		this.id = this.helpers.guid();
		this.multiple = this.multiple || false;
		this.inputName = this.inputName || 'select';
		this.checkListValue(this.value as FilterConcept);
	}

	checkListValue(valueSelected: FilterConcept) {
		if (this.items && !this.items.find(item => item.id_code == valueSelected.id_code)) {
			this.items.unshift(valueSelected);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value && changes.value.currentValue) {
			if (this.multiple) {
				const values: FilterConcept[] = changes.value.currentValue;

				this.selected = values.map(val => val.id_code);
				this.originalValue = this.selected.map(id => id);
			} else {
				const values: FilterConcept = changes.value.currentValue;

				this.selected = values.id_code;
				this.originalValue = this.selected;
				this.checkListValue(values);
			}
		}
	}

	public onAction = (option: boolean) => {
		if (option) {
			let response: FilterConcept[] | FilterConcept;

			if (this.multiple) {
				const selected = this.selected as string[];

				response = this.items.filter(el => selected.some(se => se == el.id_code));
				this.originalValue = [...selected];
			} else {
				response = this.items.find(el => el.id_code == this.selected);
				this.originalValue = this.selected;
			}

			this.submit.emit({
				inputName: this.inputName,
				value: response,
			});
		} else {
			if (this.multiple) {
				const selected = this.originalValue as string[];
				this.selected = [...selected];
			} else {
				this.selected = this.originalValue;
			}

			this.submit.emit({
				inputName: null,
				value: null,
			});
		}
	}

}
