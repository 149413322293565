import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import {  InputsMinuteParams } from 'src/app/interfaces/filters-inputs.interface';
import { HelpersServices } from '../../helpers/helpers.services';

@Component({
	selector: 'app-delivery-solot-card',
	templateUrl: './delivery-solot-card.component.html',
	styleUrls: ['./delivery-solot-card.component.scss']
})
export class DeliverySolotCardComponent implements OnInit {
	@Input() solot: any = { };
	@Input() deliverySolotDescription?: string;
	@Input() deliverySolotDate?: Date;
	@Input() deliveryStatusCustomer?: string;
	@Input() deliveryStatusClaro?: string;
	@Output() minuteChange: EventEmitter<InputsMinuteParams> = new EventEmitter<InputsMinuteParams>();
	@Output() minuteDelete: EventEmitter<InputsMinuteParams> = new EventEmitter<InputsMinuteParams>();

	savingTableChanges = false;
	bgConfig = { isAnimated: true, dateInputFormat: 'DD/MM/YYYY' };

	constructor(
		private helpers: HelpersServices
	) { }

	ngOnInit() {
		this.deliverySolotDescription = '';
	}

	isChange() {
		this.minuteChange.emit(this.solot);
	}

	deleteSolotList() {
		this.minuteDelete.emit(this.solot);
	}

}
