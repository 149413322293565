import { Directive, ElementRef, Input, OnInit, HostListener } from '@angular/core';
import { DecodedUserResponse, HelpersServices } from '../helpers/helpers.services';
import { HasDisableType } from '../interfaces/directives.interface';
import { UserPermission } from '../interfaces/users.interface';

@Directive({
	// tslint:disable-next-line: directive-selector
	selector: '[hasDisable]'
})
export class HasDisableDirective implements OnInit {

	constructor(
		private el: ElementRef,
		private helpers: HelpersServices,
	) { }

	@Input() hasDisable: HasDisableType;

	ngOnInit() {
		const userAuth = this.helpers.getDecodedUser();
		this.init(userAuth as DecodedUserResponse);
	}

	init(userAuth: DecodedUserResponse) {
		if (userAuth) {
			const { user: { permissions: userPermissions } } = userAuth;
			if (this.hasDisable) {
				if (!(userPermissions && userPermissions[0] && userPermissions[0][this.hasDisable.name])) {
					this.el.nativeElement.disabled = true;
				} else {
					if (typeof this.hasDisable.op === 'undefined' || typeof this.hasDisable.op[this.hasDisable.op.key] === 'undefined') {
						this.el.nativeElement.disabled = false;
					} else {
						this.el.nativeElement.disabled = this.hasDisable.op[this.hasDisable.op.key];
					}
				}
			} else {
				this.el.nativeElement.disabled = false;
			}
		}
	}

	hasDisableInt(hd: any, op) {
		const userAuth = this.helpers.getDecodedUser();
		const { user: { permissions: userPermissions } } = userAuth as DecodedUserResponse;
		for (const i of hd._results) {
			if (i.hasDisable.op.key == op.key) {
				if ((userPermissions && userPermissions[0] && userPermissions[0][i.hasDisable.name])) {
					i.el.nativeElement.disabled = op[i.hasDisable.op.key];
				}
			}
		}
	}

}
