import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { ProjectInputsList } from 'src/app/interfaces/filters-inputs.interface';
import { FilterConcept, Project } from 'src/app/interfaces/projects.interfaces';
import { LabelToggleInputEditEmitterType } from '../molecules/label-toggle-input-edit/label-toggle-input-edit.component';
import * as moment from 'moment';

@Component({
	selector: 'app-project-card',
	templateUrl: './project-card.component.html',
	styleUrls: ['./project-card.component.scss']
})

export class ProjectCardComponent implements OnInit, OnChanges {
	@Input() project: Project;
	@Input() index: number;
	@Input() inputsList: ProjectInputsList;
	@Input() saving: boolean;
	@Input() expanded: boolean;
	@Output() save: EventEmitter<Project> = new EventEmitter<Project>();

	progressBadgeColor: string;
	projectOriginal: Project;
	iconPhase: string;
	iconClassification: string;
	iconProgress: string;
	iconSupplies: string;

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
		private router: Router,
		private location: Location,
	) { }

	ngOnInit() {
		this.iconPhase = (!this.project.autoStatus) ? 'fa-user-edit' : '';
		this.iconClassification = (!this.project.autoManagementType) ? 'fa-user-edit' : '';
		this.iconProgress = (!this.project.autoProgress) ? 'fa-user-edit' : '';
		this.iconSupplies = (!this.project.autoSupplies) ? 'fa-user-edit' : '';

		this.setProcessColors();
		this.project.lastUpdate = this.project.lastUpdate ? moment(this.project.lastUpdate.substr(0, 19)).format('DD-MM-YYYY HH:mm:ss') : '' ;
		this.projectOriginal = {
			...this.project,
		};
	}

	setProcessColors() {
		const progressColors = {
			orange: 'badge-warning',
			green: 'badge-success',
			red: 'badge-danger',
		};
		this.project.progressColor = this.getProgressColor(this.project.progress);
		this.progressBadgeColor = progressColors[this.project.progressColor];
	}

	getProgressColor(progress: number = 0): string {
		if (progress >= 60 && progress < 80) {
			return 'orange';
		} else if (progress >= 80) {
			return 'green';
		}

		return 'red';
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.inputsList) {
			this.inputsList = changes.inputsList.currentValue;
			const {
				projectStatusList = [],
			} = this.inputsList;
			const status = projectStatusList.find(item => item.id_code == this.project.statusId);

			this.project.statusName = status && status.ds_name;
		}

		if (changes.project != undefined && changes.project.currentValue.expandProject != undefined) {
			this.expanded = changes.project.currentValue.expandProject;
		}
	}

	navigateToDetails() {
		let url = this.location.path();
		url = btoa(url);
		this.router.navigate([
			'/projects/list',
			this.project.projectId,
			'details', url
		]);
	}

	onIndividualInputSubmit(field: LabelToggleInputEditEmitterType) {
		this.project[field.inputName] = field.value;
		this.project = {
			...this.project,
			index : this.index,
			expandProject: this.expanded,
			categoryCode : 'ESTG',
			categoryId : '1'
		};

		if (field.inputName == 'progress') {
			this.setProcessColors();
		}

		this.emitChanges();
	}

	onIndividualSelectSubmit(field: LabelToggleInputEditEmitterType) {
		const selectedField = field.value as FilterConcept;
		const fieldName = field.inputName;
		const fieldId = this.getNameAttributeOfId(field.inputName);

		this.project[fieldId] = selectedField.id_code;
		this.project[fieldName] = selectedField.ds_name;
		if (fieldName == 'suppliesManager') {
			this.project = {
				...this.project,
				suppliesMark: 1
			};
		}

		this.project = {
			...this.project,
			index : this.index
		};

		this.emitChanges();
	}

	emitChanges() {
		const changes = this.getChangedAttributes();
		this.project.lastUpdate = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');

		if (Object.keys(changes).length > 1) {
			this.projectOriginal = { ...this.project };
			this.save.emit(changes);
		}
	}

	getChangedAttributes(): Project {
		const response: Project = {
			projectId: this.project.projectId,
		};

		for (const key in this.project) {
			if (Object.prototype.hasOwnProperty.call(this.project, key) && this.project[key] != this.projectOriginal[key]) {
				response[key] = this.project[key];
			}
		}

		return response;
	}

	teamList() {
		const param = {
			projectId : this.project.projectId
		};
		this.api.getProjectTeam(param).subscribe((response) => {
			if (response.status) {
				this.inputsList.suppliesManagerList = response.data && response.data.suppliesManagers;
				this.inputsList.techLeadersList = response.data && response.data.leaderTelco;
				this.inputsList.tiLeadersList = response.data && response.data.leaderTI;
				this.setCurrentSelectedIfNotExist();
			}
		}, () => this.helpers.handleErrors());
	}

	setCurrentSelectedIfNotExist() {
		if (!this.inputsList.suppliesManagerList.some(item => item.id_code == this.project.suppliesManagerId)) {
			 this.inputsList.suppliesManagerList.unshift({
				id_code: this.project.suppliesManagerId,
				ds_name: this.project.suppliesManager,
			});
		}
	}

	private getNameAttributeOfId(name: string): string {
		const ids = {
			suppliesManager: 'suppliesManagerId',
			techLeaderName: 'techLeaderId',
			manager: 'managerId',
			leaderName: 'leaderId',
			engineerName: 'engineerId',
			edac: 'edacId',
			statusName: 'statusId',
			managementTypeName: 'managementType',
			tiLeaderName: 'tiLeaderId'
		};

		return ids[name];
	}

}
