import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ProjectsServices } from '../../pages/projects/projects.services';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { HelpersServices, NotifyType } from '../../helpers/helpers.services';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { InputsFilterParams } from 'src/app/interfaces/filters-inputs.interface';

interface SolotsParameter {
	solotId?: string;
	lastRelevantsFacts?: string;
}

export interface CloseMonthStructure {
	pivotMonth: string;
	dateRange: Date[];
	description?: string;
	solotsIncluded: any[];
	solotsFound: any[];
}

@Component({
	selector: 'app-solots-table',
	templateUrl: './solots-table.component.html',
	styleUrls: ['./solots-table.component.scss']
})

export class SolotsTableComponent implements OnInit, OnChanges {
	@Input() solots: any[];
	@Input() isLoading: boolean;
	@Input() pageInfo: any;
	@Input() isDownloading: boolean;
	@Input() selectAllSolotsParams: any = { };

	@Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() searchingText: EventEmitter<any> = new EventEmitter<any>();
	@Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() sortPage: EventEmitter<any> = new EventEmitter<any>();
	@Output() download: EventEmitter<any> = new EventEmitter<any>();

	projectId: any;
	solotListFiltered: any[] = [];
	solotStatusList: any[];
	closureChangeCauseList: FilterConcept[];
	solotNotesList: any[];
	taskStatusList: any[];
	projectedClosingMonthList: FilterConcept[];
	closingProbability: FilterConcept[];
	savingTableChanges = false;
	newLatestRelevantFacts: string;
	currentSolotId: any;
	solotSearchText = '';
	isSearching = false;
	urlUpload = `${environment.baseUrl}/projects/files`;
	checkAll = false;
	exceptThisSolotsIds: any[] = [];
	openingRelevantFacts: boolean;
	savingLastRelevantsFacts: boolean;
	pivotMonthList: FilterConcept[];
	solotsIncludeList: any[] = [];
	isSearchingSolots = false;
	isPivotMonthInfoLoading = false;
	closureMonth: CloseMonthStructure = {
		pivotMonth: '',
		dateRange: [],
		description: '',
		solotsIncluded: [],
		solotsFound: [],
	};
	page = {
		page: 0,
		size: 0,
		count: 0,
		sorts: [],
	};
	errorMessage: NotifyType = {
		title: 'Error',
		message: 'Ocurrió un error inesperado!',
		type: 'danger',
	};

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
		private modalService: NgbModal,
		config: NgbModalConfig,
	) {
		config.backdrop = 'static';
	}

	ngOnInit() {
		const params = { workflowId: 2 };

		this.getStatusList(params);
		this.getProjectionOptions();
		this.clearClosureMonth();
	}

	clearClosureMonth(): void {
		const today = new Date();
		const lastMonth = new Date();
		lastMonth.setDate(today.getDate() - 30);

		this.closureMonth.dateRange = [lastMonth, today];
		this.closureMonth.description = '';
		this.closureMonth.pivotMonth = '';
		this.closureMonth.solotsIncluded = [];
		this.closureMonth.solotsFound = [];
	}

	ngOnChanges(changes) {
		if (changes.solots && changes.solots.currentValue) {
			this.onStatusChange(true);
		}
		if (changes.pageInfo && changes.pageInfo.currentValue) {
			const { page, size, total: count } = changes.pageInfo.currentValue;
			this.page = { ...this.page, ...{ page, size, count } };
		}
	}

	onSolotSearch(callStatusChange?: boolean, sendCallback?: boolean): void {
		const search = new RegExp(this.solotSearchText || '', 'gi');

		this.solotListFiltered = this.solots ? this.solots.filter(solot => JSON.stringify(solot).match(search)) : [];

		if (sendCallback && !this.isSearching) {
			this.isSearching = true;
			setTimeout(() => {
				this.searchingText.emit(this.solotSearchText);
				this.isSearching = false;
			}, 500);
		}

		if (callStatusChange) {
			this.onStatusChange();
		}
	}

	getStatusList(params): void {
		this.api.getStatuses(params).subscribe((response) => {
			if (response.status) {
				this.solotStatusList = response.data || [];

				setTimeout(() => {
					this.onStatusChange(true, true);
				}, 2000);
			}
		});
	}

	onStatusChange(callSearch?: boolean, sendCallback?: boolean): void {
		if (callSearch) {
			this.onSolotSearch();
		}

		if (sendCallback) {
			this.statusChange.emit(this.solotStatusList.filter(status => status.checked));
		}

		if (this.solotStatusList && this.solotStatusList.some(status => status.checked)) {
			this.solotListFiltered = this.solotListFiltered.filter(solot => {
				return this.solotStatusList.some(status => (status.id_code == solot.statusId && status.checked));
			});
		}
	}

	getWarningAtSaveMultipleRow(): any {
		return swal.fire({
			title: 'Se detectaron filas marcadas',
			html: 'Desea actualizar con estos cambios todas las filas marcadas?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Actualizar todas!',
			cancelButtonText: 'No, Solo fila actual!'
		});
	}

	beforeSaveRelevantFacts(): void {
		if (this.solots.some(solot => solot.checked)) {
			this.getWarningAtSaveMultipleRow().then(response => {
				this.saveNewRelevantFact(response.value);
			});
		} else {
			this.saveNewRelevantFact(false);
		}
	}

	getAllSelectedParams(allChecked: boolean): SolotsParameter[] {
		if (allChecked) {
			return this.solots
				.filter(solot => solot.checked)
				.map(solot => ({
					solotId: solot.solotId,
					lastRelevantsFacts: this.newLatestRelevantFacts
				}));
		} else {
			return [
				{
					solotId: this.currentSolotId,
					lastRelevantsFacts: this.newLatestRelevantFacts
				},
			];
		}
	}

	updateSolotList(solotsUpdated: any[]): void {
		for (const solot of this.solots) {
			if (solotsUpdated.some(solotUpdated => solotUpdated.solotId == solot.solotId)) {
				solot.lastRelevantsFacts = solotsUpdated[0].lastRelevantsFacts;
			}
		}
		this.newLatestRelevantFacts = '';
	}

	saveNewRelevantFact(saveAllChecked: boolean): void {
		const params = this.getAllSelectedParams(saveAllChecked);

		this.savingLastRelevantsFacts = true;
		this.api.setSolotNote(params).subscribe(
			(response) => {
				this.savingLastRelevantsFacts = false;

				if (response.status) {
					this.updateSolotList(params);
					this.getSolotNotesList(this.currentSolotId);
					this.helpers.notify({
						title: 'Guardado',
						message: 'Datos actualizados exitosamente!',
						type: 'success',
					});
				} else {
					this.helpers.notify(this.errorMessage);
				}
			},
			(error) => {
				this.savingLastRelevantsFacts = false;
				this.helpers.handleErrors(error);
			}
		);
	}

	beforeSaveSelectRowInfo(rowSelected: object): void {
		if (this.solots.some(solot => solot.checked)) {
			this.getWarningAtSaveMultipleRow().then(response => {
				if (response.value) {
					this.saveAllCheckedSolots(rowSelected);
				} else {
					this.saveSolotProjection(rowSelected);
				}
			});
		} else {
			this.saveSolotProjection(rowSelected);
		}
	}

	saveSolotProjection(solot): void {
		const params = {
			solotId: solot.solotId,
			closureProjected: solot.closureProjected,
			closureChance: solot.closureChance,
			closureChangeCause: solot.closureChangeCause,
		};

		this.savingSolotsProjection([params]);
	}

	saveAllCheckedSolots(solot): void {
		const params = this.solots
			.filter(item => item.checked)
			.map(item => {
				item.checked = false;
				item.closureProjected = solot.closureProjected;
				item.closureChance = solot.closureChance;
				item.closureChangeCause = solot.closureChangeCause;

				return {
					solotId: item.solotId,
					closureProjected: item.closureProjected,
					closureChance: item.closureChance,
					closureChangeCause: item.closureChangeCause,
				};
			});

		this.savingSolotsProjection(params, true);
	}

	savingSolotsProjection(data, alert?): void {
		const params = {
			data,
			checkAll: alert && this.checkAll,
			except: this.exceptThisSolotsIds,
			params: this.selectAllSolotsParams,
		};
		this.savingTableChanges = true;
		this.api.setSolotClosureProjection(params).subscribe(
			response => {
				this.checkAll = false;
				this.savingTableChanges = false;
				if (response.status) {
					if (alert) {
						swal.fire(
							'Guardado!',
							'Registros actualizados',
							'success'
						);
					} else {
						this.helpers.notify({
							title: 'Guardado',
							message: 'Datos actualizados exitosamente!',
							type: 'success',
							options: { timeOut: 3000 },
						});
					}
				} else {
					this.helpers.handleErrors({ message: response.message });
				}
			},
			error => {
				this.checkAll = false;
				this.savingTableChanges = false;
				this.helpers.handleErrors(error);
			}
		);
	}

	onSolotCheckRow(solot): void {
		setTimeout(() => {
			if (solot.checked) {
				this.exceptThisSolotsIds = this.exceptThisSolotsIds.filter(solotId => solotId !== solot.solotId);
			} else {
				this.exceptThisSolotsIds.push(solot.solotId);
			}
		}, 300);
	}

	getProjectionOptions(): void {
		this.api.getClosureChangeCauseList().subscribe((response) => {
			this.closureChangeCauseList = response.data || [];
		});

		this.api.getClosureProjectionList().subscribe((response) => {
			this.projectedClosingMonthList = response.data || [];
		});

		this.api.getPivotMonthList().subscribe((response) => {
			this.pivotMonthList = response.data || [];
		});

		this.closingProbability = [
			{ id_code: '50', ds_name: '50%' },
			{ id_code: '100', ds_name: '100%' },
		];
	}

	getPivotMountDetails(content): void {
		this.isPivotMonthInfoLoading = true;
		this.api.getPivotMonthDetail().subscribe(
			response => {
				this.isPivotMonthInfoLoading = false;

				if (response.status) {
					const [pivot] = response.data;

					if (pivot) {
						const start = pivot.startDate && pivot.startDate.substr(0, 10);
						const end = pivot.endDate && pivot.endDate.substr(0, 10);

						this.solotsIncludeList = [];
						this.closureMonth.pivotMonth = pivot.id_code;
						this.closureMonth.description = pivot.description;
						this.closureMonth.solotsIncluded = pivot.solotIds;
						this.closureMonth.dateRange = [
							new Date(start),
							new Date(end),
						];
					}

					this.openMonthClosureModal(content);
				}
			},
			error => {
				this.isPivotMonthInfoLoading = false;
				this.helpers.handleErrors(error);
			},
		);
	}

	openViewRelevantFacts(content, solotId) {
		const modalConfig = {
			size: 'lg',
			centered: true,
		};

		this.solotNotesList = [];
		this.getSolotNotesList(solotId, () => {
			this.modalService.open(content, modalConfig).result.then(
				() => {
					this.currentSolotId = false;
					this.newLatestRelevantFacts = '';
				}
			);
		});
	}

	getSolotNotesList(solotId: number, callback: (list: any) => any = (list: any) => { }): void {
		this.openingRelevantFacts = true;
		this.api.getSolotNotesList({ solotId }).subscribe(
			(response) => {
				this.openingRelevantFacts = false;

				if (response.status) {
					this.currentSolotId = solotId;
					this.solotNotesList = response.data.map((note) => {
						note.updatedAt = note.updatedAt ? moment(note.updatedAt).format('DD/MM/YYYY HH:mm') : '-';

						return note;
					});

					callback(this.solotNotesList);
				} else {
					this.helpers.handleErrors(response);
				}
			},
			(error) => {
				this.openingRelevantFacts = false;
				this.helpers.handleErrors(error);
			}
		);
	}

	openModalTaskStatusDescriptions(content, solotId) {
		const modalConfig = {
			size: 'lg',
			centered: true,
		};

		this.api.getSolotTaskList({ solotId }).subscribe(
			response => {
				this.taskStatusList = [];

				if (response.status) {
					this.taskStatusList = response.data.map((task) => {
						task.startDate = task.startDate ? moment(task.startDate).format('DD/MM/YYYY HH:mm') : '-';
						task.commitmentDate = task.commitmentDate ? moment(task.commitmentDate).format('DD/MM/YYYY HH:mm') : '-';

						return task;
					});

					this.modalService.open(content, modalConfig);
				}
			},
			error => this.helpers.handleErrors(error),
		);
	}

	uploadSolotsItem(content, projectId): void {
		this.projectId = projectId;
		const modalConfig = {
			size: 'xl',
			centered: true,
		};

		this.modalService.open(content, modalConfig);
	}

	downloadSolotsTable(): void {
		this.download.emit();
	}

	setPage({ offset }): void {
		this.page.page = offset;
		this.pageChange.emit(this.page);
	}

	setSort({ sorts = [] }) {
		this.page.sorts = sorts.map(sort => JSON.stringify(sort));
		this.sortPage.emit(this.page);
	}

	onCheckAll(checkAll) {
		this.exceptThisSolotsIds = [];
		for (const solot of this.solotListFiltered) {
			solot.checked = checkAll;
		}
	}

	openMonthClosureModal(content) {
		const modalConfig = {
			size: 'lg',
			centered: true,
		};

		this.modalService.open(content, modalConfig).result.then(
			response => {
				if (response === 'execute') {
					swal.fire({
						title: 'Esta seguro de ejecutar el cierre de mes?',
						text: 'Asegurese de tomar todas las precauciones',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Si, Ejecutar',
						cancelButtonText: 'No, Cancelar!',
						showLoaderOnConfirm: true,
						preConfirm: () => {
							return this.runClosureMonth();
						},
					}).then((result) => {
						if (result.value) {
							// this.clearClosureMonth();
							swal.fire({
								icon: 'success',
								title: 'Cierre de mes',
								text: 'Cierre de mes ejecutado exitosamente!'
							});
						}
					});
				} else if (response === 'save') {
					this.setClosureMonthInfo();
				}
			},
			error => console.log(error),
		);
	}

	getClosureInfo(): any {
		const {
			pivotMonth: pivotMonthId,
			description
		} = this.closureMonth;
		const [startDate, endDate] = this.closureMonth.dateRange;
		const solotsExceptions = this.closureMonth.solotsIncluded.map(solot => solot.solotId);

		return {
			pivotMonthId,
			startDate,
			endDate,
			description,
			solotsExceptions,
		};
	}

	setClosureMonthInfo(): void {
		const params = {
			data: this.getClosureInfo()
		};

		this.api.setClosureMonthInfo(params).subscribe(
			response => {
				if (response.status) {
					// this.clearClosureMonth();
					this.helpers.notify({
						type: 'success',
						title: 'Guardado!',
						message: 'Configuración guardada con éxito!',
					});
				}
			},
			error => {
				this.helpers.handleErrors(error);
			}
		);
	}

	runClosureMonth(): any {
		const params = {
			data: this.getClosureInfo()
		};

		return new Promise((resolve, reject) => {
			this.api.runClosureMonth(params).subscribe(
				({ status, message }) => {
					if (status) {
						resolve(true);
					} else {
						reject(false);
						this.helpers.handleErrors({ message });
					}
				},
				error => {
					this.helpers.handleErrors(error);
					reject(false);
				}
			);
		});
	}

	searchingSolotsSuggest({ term }): void {
		const params: InputsFilterParams = {
			txt: term,
			page: 0,
			size: 100,
			sorts: [],
		};

		if (term && term.length >= 5) {
			this.isSearchingSolots = true;
			// debounceTime(500)
			this.api.getSolotTableList(params).subscribe(
				response => {
					this.isSearchingSolots = false;
					if (response.status) {
						this.solotsIncludeList = response.data || [];
					}
				},
				error => {
					this.isSearchingSolots = false;
					this.helpers.handleErrors(error);
				},
			);
		}
	}

	addSolotsToinclude(): void {
		const solotsIndexed = this.closureMonth.solotsIncluded
			.reduce((res, solot) => {
				res[solot.solotId] = true;

				return res;
			}, { });

		this.closureMonth.solotsIncluded = this.closureMonth.solotsIncluded
			.concat(
				this.closureMonth.solotsFound
					.filter(solot => !solotsIndexed[solot.solotId])
					.splice(0)
			);
		this.closureMonth.solotsFound = [];
	}

	removeIncludedSolot(solotId: string): void {
		this.closureMonth.solotsIncluded = this.closureMonth.solotsIncluded
			.filter(solot => solot.solotId !== solotId);
	}
}
