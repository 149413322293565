import { Component, Input, OnInit } from '@angular/core';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';

@Component({
	selector: 'app-solot-card3',
	templateUrl: './solot-card3.component.html',
	styleUrls: ['./solot-card3.component.scss']
})
export class SolotCard3Component implements OnInit {
	@Input() solot: any = { };
	@Input() projectedClosingMonthList: FilterConcept[] = [];
	@Input() closureChangeCauseList: FilterConcept[] = [];
	savingTableChanges = false;
	showMore = false;

	constructor(
		private helpers: HelpersServices
	) { }

	ngOnInit() {
	}

}
