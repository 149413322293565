import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KpiGroup, KpiGroupResponse, KpiItem } from 'src/app/interfaces/projects.interfaces';

@Component({
	selector: 'app-kpi-group',
	templateUrl: './kpi-group.component.html',
	styleUrls: ['./kpi-group.component.scss']
})
export class KpiGroupComponent implements OnInit {
	@Input() kpiGroup: KpiGroup;
	@Output() select = new EventEmitter<KpiGroupResponse>();

	constructor() { }

	ngOnInit() {
	}

	onSelected(kpiItem: KpiItem) {
		this.select.emit({
			kpiGroupId: this.kpiGroup.kpiId,
			kpiItemId: kpiItem.kpiId,
			checked: kpiItem.checked,
		});
	}

}
