import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelpersServices, NotifyType } from 'src/app/helpers/helpers.services';
import * as moment from 'moment';

moment.locale('es');
import {
	CommonInputChangeParams,
	ProjectFiltersInterface,
	ProjectInputsFilterData,
	ProjectInputsList,
} from 'src/app/interfaces/filters-inputs.interface';
import {
	GetProjectListParams,
	UserConceptParams,
	UsersListConceptPromiseResponse
} from 'src/app/interfaces/projects.interfaces';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { UserListByConceptParams } from '../../../interfaces/projects.interfaces';
import { UsersConcepts } from './users-list-concepts';
import { InputParameters } from '../../../interfaces/filters-inputs.interface';

export interface DateRangePickerSelected {
	startDate: moment.Moment;
	endDate: moment.Moment;
}

@Component({
	selector: 'app-entities-filters',
	templateUrl: './entities-filters.component.html',
	styleUrls: ['./entities-filters.component.scss']
})
export class EntitiesFiltersComponent implements OnInit {

	constructor(
		private api: ProjectsServices,
		private helper: HelpersServices,
	) { }
	@Input() expanded = true;
	@Input() params: GetProjectListParams;

	@Output() filtering = new EventEmitter<ProjectFiltersInterface>();

	inputsData: ProjectInputsFilterData;
	inputsList: ProjectInputsList;
	ranges: { [name: string]: moment.Moment[] } = {
		Hoy: [moment(), moment()],
		Ayer: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Menos de 7 días': [moment().subtract(6, 'days'), moment()],
		'Entre 7 y 14 días': [moment().subtract(13, 'days'), moment().subtract(6, 'days')],
		'Entre 15 y 30 días': [moment().subtract(29, 'days'), moment().subtract(14, 'days')],
	};

	locale = {
		firstDay: 1,
		daysOfWeek: moment.weekdaysMin(),
    	monthNames: moment.monthsShort(),
	}

	ngOnInit() {
		this.inputsData = { };
		this.inputsList = { };
		this.params = { ...this.params };
		this.setManualListsInfo();
		this.getBuildingsList();
		this.getMonthClosureProjectionList();
		this.getManagersList(true);
		this.getBusinessExecutiveListList(true);
		this.getTiLeadersList(true);
		this.getTelcoLeadersList(true);
		this.getClassificationTypesList(true);
		this.getProjectSubTypesList(true);
		this.fillCommonsInputs(true);
	}

	notifyInputChange(inputName: InputParameters) {
		this.filtering.emit({
			inputsFilterData: this.inputsData,
			inputsFilterParams: this.params,
			inputsFilterList: this.inputsList,
			inputChanged: inputName
		});
	}

	getBuildingsList(): void {
		this.inputsList.buildingsList = [];
		this.api.getBuildings().subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.buildingsList = response.data || [];
					this.inputsData.buildingsSelected = this.helper.getItemSelected(
						this.inputsList.buildingsList,
						this.params.buildingId,
					);
					this.notifyInputChange(InputParameters.BUILDINGS);
				}
			},
			(error) => this.helper.handleErrors(error)
		);
	}

	getMonthClosureProjectionList(): void {
		this.inputsList.closureProjectionList = [];
		this.api.getMonthClosureProjectionList().subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.closureProjectionList = response.data || [];
					this.inputsData.closureProjectionSelected = this.helper.getItemSelectedName(
						this.inputsList.closureProjectionList,
						this.params.closureProjection,
					);
					this.notifyInputChange(InputParameters.CLOSURE_PROJECTION);
				}
			},
			(error) => this.helper.handleErrors(error)
		);
	}

	getManagersList(fromOnInit?: boolean): void {
		this.inputsList.managersList = [];
		this.api.getManagers(this.params).subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.managersList = response.data || [];

					if (fromOnInit) {
						this.inputsData.managersSelected = this.helper.getItemSelected(
							this.inputsList.managersList,
							this.params.managerId,
						);
						if (this.inputsData.managersSelected.length) {
							this.notifyInputChange(InputParameters.MANAGERS);
						}
					}
				}
			},
			(error) => this.helper.handleErrors(error)
		);
	}

	getTiLeadersList(fromOnInit?: boolean): void {
		this.inputsList.tiLeadersList = [];
		this.api.getTiList(this.params).subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.tiLeadersList = response.data || [];
					if (fromOnInit) {
						this.inputsData.tiLeadersSelected = this.helper.getItemSelected(
							this.inputsList.tiLeadersList,
							this.params.tiLeaderId,
						);
						if (this.inputsData.tiLeadersSelected.length) {
							this.notifyInputChange(InputParameters.TI_LEADERS);
						}
					}
				}
			},
			(error) => this.helper.handleErrors(error)
		);
	}

	getTelcoLeadersList(fromOnInit?: boolean): void {
		this.inputsList.techLeadersList = [];
		this.api.getTelcoList(this.params).subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.techLeadersList = response.data || [];
					if (fromOnInit) {
						this.inputsData.techLeadersSelected = this.helper.getItemSelected(
							this.inputsList.techLeadersList,
							this.params.techLeaderId,
						);
						if (this.inputsData.techLeadersSelected.length) {
							this.notifyInputChange(InputParameters.TECH_LEADERS);
						}
					}
				}
			},
			(error) => this.helper.handleErrors(error)
		);
	}

	getClassificationTypesList(fromOnInit?: boolean): void {
		this.inputsList.projectClassificationTypes = [];
		this.api.getListConcept({ conceptCode: '2' }).subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.projectClassificationTypes = response.data || [];
					if (fromOnInit) {
						this.inputsData.projectClassificationTypesSelected = this.helper.getItemSelected(
							this.inputsList.projectClassificationTypes,
							this.params.managementType,
						);
						if (this.inputsData.projectClassificationTypesSelected.length) {
							this.notifyInputChange(InputParameters.PROJECT_CLASSIFICATION_TYPE);
						}
					}
				}

			},
			(error) => this.helper.handleErrors(error)
		);
	}

	getBusinessExecutiveListList(fromOnInit?: boolean): void {
		this.inputsList.businessExecutivesList = [];
		this.api.getBusinessExecutiveList(this.params).subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.businessExecutivesList = response.data || [];
					if (fromOnInit) {
						this.inputsData.businessExecutivesSelected = this.helper.getItemSelected(
							this.inputsList.businessExecutivesList,
							this.params.businessExecutiveId,
						);
						if (this.inputsData.businessExecutivesSelected.length) {
							this.notifyInputChange(InputParameters.TI_LEADERS);
						}
					}
				}
			},
			(error) => this.helper.handleErrors(error)
		);
	}

	fillCommonsInputs(fromOnInit?: boolean) {
		const usersConcepts = UsersConcepts
			.map(concept => {
				concept.fromOnInit = fromOnInit;

				return concept;
			});

		const promises = usersConcepts
			.map(concept => this.getUsersByConcept({
				concept: concept.conceptName,
				buildingId: this.params.buildingId,
				managerId: this.params.managerId,
			}));

		Promise.all(promises)
			.then(responses => {
				responses.forEach(response => {
					const concept = usersConcepts.find(item => item.conceptName == response.concept);

					concept.conceptData = response.data;
					this.setListUserDataByConcept(concept);
				});
			})
			.catch(error => this.helper.handleErrors(error));
	}

	setListUserDataByConcept(concept: UserConceptParams) {
		this.inputsList[concept.conceptListName] = concept.conceptData || [];

		if (concept.fromOnInit) {
			this.inputsData[concept.conceptSelectedName] = this.helper.getItemSelected(
				this.inputsList[concept.conceptListName],
				this.params[concept.conceptIdName],
			);
		}

		this.notifyInputChange(concept.name);
	}

	getUsersByConcept(params: UserListByConceptParams): Promise<UsersListConceptPromiseResponse> {
		return new Promise((resolve, reject) => {
			this.api.getUsersListByConcept(params).subscribe((response) => {
				if (response.status) {
					resolve({
						concept: params.concept,
						data: response.data,
					});
				} else {
					reject(response.message);
				}
			});
		});
	}

	getProjectSubTypesList(fromOnInit?: boolean) {

		this.inputsList.projectTypeList = [];
		this.api.getTypesList({ conceptId: '1' })
			.subscribe(response => {
				if (response.status) {
					this.inputsList.projectTypeList = response.data || [];
					if (fromOnInit) {
						this.inputsData.projectTypeSelected = this.helper.getItemSelected(
							this.inputsList.projectTypeList,
							this.params.typeId,
						);
					}
				}
			});
	}

	onBuildingChange() {
		this.onCommonInputChange({
			paramIdName: 'buildingId',
			dataSelectedName: 'buildingsSelected',
			inputName: InputParameters.BUILDINGS,
		});
		this.getManagersList();
		this.fillCommonsInputs();
		this.getTiLeadersList();
		this.getTelcoLeadersList();
	}

	onClosureProjectionChange() {
		this.onCommonInputChangeString({
			paramIdName: 'closureProjection',
			dataSelectedName: 'closureProjectionSelected',
			inputName: InputParameters.CLOSURE_PROJECTION,
		});
	}

	onColocationChange() {
		this.onCommonInputChange({
			paramIdName: 'colocation',
			dataSelectedName: 'colocationSelected',
			inputName: InputParameters.COLOCATION,
		});
	}

	onManagersChange() {
		this.onCommonInputChange({
			paramIdName: 'managerId',
			dataSelectedName: 'managersSelected',
			inputName: InputParameters.MANAGERS,
		});
		this.fillCommonsInputs();
	}

	onTechLeadersChange() {
		this.onCommonInputChange({
			paramIdName: 'techLeaderId',
			dataSelectedName: 'techLeadersSelected',
			inputName: InputParameters.TECH_LEADERS,
		});
	}

	onTiLeadersChange() {
		this.onCommonInputChange({
			paramIdName: 'tiLeaderId',
			dataSelectedName: 'tiLeadersSelected',
			inputName: InputParameters.TI_LEADERS,
		});
	}

	onEngineersChange() {
		this.onCommonInputChange({
			paramIdName: 'engineerId',
			dataSelectedName: 'engineersSelected',
			inputName: InputParameters.ENGINEERS,
		});
	}

	onBusinessExecutiveChange() {
		this.onCommonInputChange({
			paramIdName: 'businessExecutiveId',
			dataSelectedName: 'businessExecutivesSelected',
			inputName: InputParameters.BUSINESS_EXECUTIVE,
		});
	}

	onSalesExecutiveChange() {
		this.onCommonInputChange({
			paramIdName: 'salesExecutiveId',
			dataSelectedName: 'salesExecutivesSelected',
			inputName: InputParameters.SALES_EXECUTIVE,
		});
	}

	onEdacChange() {
		this.onCommonInputChange({
			paramIdName: 'edacId',
			dataSelectedName: 'edacSelected',
			inputName: InputParameters.EDAC,
		});
	}

	onProjectClassificationTypeChange(): void {
		this.params.managementType = this.helper.prepareArrayToSendParameter(
			this.inputsData.projectClassificationTypesSelected
		);
		this.notifyInputChange(InputParameters.PROJECT_CLASSIFICATION_TYPE);
	}

	onOnTimeStatusChange(): void {
		this.params.onTimeStatus = this.helper.prepareArrayToSendParameter(
			this.inputsData.projectOnTimeStatusSelected
		);
		this.notifyInputChange(InputParameters.ON_TIME_STATUS);
	}

	onSanctionStatusChange(): void {
		this.params.isSanctioned = this.helper.prepareArrayToSendParameter(
			this.inputsData.projectSanctionsSelected
		);
		this.notifyInputChange(InputParameters.SANCTIONS);
	}

	onTypeChange(): void {
		this.params.typeId = this.helper.prepareArrayToSendParameter(
			this.inputsData.projectTypeSelected
		);
		this.notifyInputChange(InputParameters.TYPE);
	}

	onChooseDate({ startDate, endDate }: DateRangePickerSelected) {
		this.params.startDate = startDate && endDate ? startDate.format('YYYY-MM-DD') : null;
		this.params.endDate = startDate && endDate ? endDate.format('YYYY-MM-DD') : null;
		this.notifyInputChange(InputParameters.DATE_RANGE);
	}

	onCommonInputChange({ paramIdName, dataSelectedName, inputName }: CommonInputChangeParams) {
		this.params[paramIdName] = this.helper.prepareArrayToSendParameter(this.inputsData[dataSelectedName]);
		this.notifyInputChange(inputName);
	}

	onCommonInputChangeString({ paramIdName, dataSelectedName, inputName }: CommonInputChangeParams) {
		this.params[paramIdName] = this.helper.prepareArrayToSendParameterString(this.inputsData[dataSelectedName]);
		this.notifyInputChange(inputName);
	}

	setManualListsInfo(): void {
		this.inputsList.projectSanctionsList = [
			{ id_code: null, ds_name: 'Sin información' },
			{ id_code: '1', ds_name: 'Si' },
			{ id_code: '0', ds_name: 'No' },
		];
		this.inputsList.colocationList = [
			{ id_code: '1', ds_name: 'Si' },
			{ id_code: '0', ds_name: 'No' },
		];
		this.inputsList.projectOnTimeStatusList = [
			{ id_code: '1', ds_name: 'Dentro de plazo' },
			{ id_code: '0', ds_name: 'Fuera de plazo' },
		];
		this.inputsData.projectSanctionsSelected = this.helper.getItemSelected(
			this.inputsList.projectSanctionsList,
			this.params.isSanctioned
		);
		this.inputsData.projectOnTimeStatusSelected = this.helper.getItemSelected(
			this.inputsList.projectOnTimeStatusList,
			this.params.onTimeStatus,
		);

	}
}
