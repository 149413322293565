import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DecodedUserResponse, HelpersServices } from '../../helpers/helpers.services';
import { ROUTES } from '../sidebar/sidebar.menu';
import { Location } from '@angular/common';
import { UserAuth } from 'src/app/interfaces/users.interface';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	public focus;
	public listTitles: any[];
	public location: Location;
	sidenavOpen = true;
	sidenavChat = false;
	userAuth: UserAuth | { };

	constructor(
		location: Location,
		private router: Router,
		private helpers: HelpersServices,
	) {

		this.location = location;
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (window.innerWidth < 1200) {
					document.body.classList.remove('g-sidenav-pinned');
					document.body.classList.add('g-sidenav-hidden');
					this.sidenavOpen = false;
				}
			}
		});

	}

	ngOnInit() {
		this.listTitles = ROUTES.filter(listTitle => listTitle);
		const { user } = this.helpers.getDecodedUser() as DecodedUserResponse;
		this.userAuth = user || { };
	}
	getTitle() {
		let titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee.charAt(0) === '#') {
			titlee = titlee.slice(1);
		}

		for (const item of this.listTitles) {
			if (item.path === titlee) {
				return item.title;
			}
		}

		return 'Dashboard';
	}

	openSearch() {
		document.body.classList.add('g-navbar-search-showing');
		setTimeout(() => {
			document.body.classList.remove('g-navbar-search-showing');
			document.body.classList.add('g-navbar-search-show');
		}, 150);
		setTimeout(() => {
			document.body.classList.add('g-navbar-search-shown');
		}, 300);
	}
	closeSearch() {
		document.body.classList.remove('g-navbar-search-shown');
		setTimeout(() => {
			document.body.classList.remove('g-navbar-search-show');
			document.body.classList.add('g-navbar-search-hiding');
		}, 150);
		setTimeout(() => {
			document.body.classList.remove('g-navbar-search-hiding');
			document.body.classList.add('g-navbar-search-hidden');
		}, 300);
		setTimeout(() => {
			document.body.classList.remove('g-navbar-search-hidden');
		}, 500);
	}
	openSidebar() {
		if (document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-pinned');
			document.body.classList.add('g-sidenav-hidden');
			this.sidenavOpen = false;
		} else {
			document.body.classList.add('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-hidden');
			this.sidenavOpen = true;
		}
	}
	toggleSidenav() {
		if (document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-pinned');
			document.body.classList.add('g-sidenav-hidden');
			this.sidenavOpen = false;
		} else {
			document.body.classList.add('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-hidden');
			this.sidenavOpen = true;
		}
	}

	toggleChat() {
		if (this.sidenavChat == true) {
			this.sidenavChat = false;
		} else {
			this.sidenavChat = true;
		}
	}

	logout() {
		this.helpers.logout();
	}
}
