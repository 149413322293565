import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HelpersServices } from '../../helpers/helpers.services';
import { Solot, UfSolotsResume } from '../../interfaces/project-solot.interface';
import { Response } from 'src/app/interfaces/api-response.interface';
import {
	FilterConcept,
	GetManagersListParams,
	KpiGroup,
	Project,
	UserListByConceptParams,
	GetProjectListParams,
	GetSecondaryListParams,
	ProjectDetails
} from '../../interfaces/projects.interfaces';
import { InputsFilterParams } from 'src/app/interfaces/filters-inputs.interface';
import { UserContactInterface } from 'src/app/interfaces/users.interface';

@Injectable({
	providedIn: 'root'
})
export class ProjectsServices {
	api: string;
	headers: any;

	constructor(
		private Helpers: HelpersServices,
	) {
		this.api = environment.baseUrl;
	}

	public getBuildings(): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/buildings`,
			method: 'get',
		});
	}

	public getManagers(params?: GetManagersListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/managers`,
			method: 'get',
			params,
		});
	}

	public getBusinessExecutiveList(params?: any): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/businessExecutiveList`,
			method: 'get',
			params,
		});
	}

	public getTiList(params?: any): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/tiLeaders`,
			method: 'get',
			params,
		});
	}

	public getTelcoList(params?: any): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/telcoLeaders`,
			method: 'get',
			params,
		});
	}

	public getSolotManagersList(params?: GetManagersListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/managers`,
			method: 'get',
			params,
		});
	}

	public getSolotEngineersList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/engineers`,
			method: 'get',
			params,
		});
	}

	public getEngineers(params?: any): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/engineers`,
			method: 'get',
			params,
		});
	}

	public getResponsible(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/responsibles`,
			method: 'get',
			params,
		});
	}

	public getSolotsResponsibleList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/responsible`,
			method: 'get',
			params,
		});
	}

	public getSolotsBusinessExecutiveList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/businessExecutives`,
			method: 'get',
			params,
		});
	}

	public getSolotsSalesExecutiveList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/salesExecutives`,
			method: 'get',
			params,
		});
	}

	public getSolotsEdacList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/edac`,
			method: 'get',
			params,
		});
	}

	public getSolotsOnTimeStatusList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/onTimeStatus`,
			method: 'get',
			params,
		});
	}

	public getSolotsPfvStatusList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/pfvStatus`,
			method: 'get',
			params,
		});
	}

	public getSolotsSubTypesList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/types`,
			method: 'get',
			params,
		});
	}

	public getTypesList(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/types`,
			method: 'get',
			params,
		});
	}

	public getListConcept(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/listConcept`,
			method: 'get',
			params,
		});
	}

	public getTooltip(params?: GetSecondaryListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/allTooltip`,
			method: 'get',
			params,
		});
	}

	public getStatuses(params?: GetProjectListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/statuses`,
			method: 'get',
			params,
		});
	}

	public getListType(params?: GetProjectListParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/listType`,
			method: 'get',
			params,
		});
	}

	public getMonthClosureProjectionList(): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/closureProjectionDate`,
			method: 'get',
		});
	}

	public getKpis(params?: any): Observable<Response<KpiGroup[]>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/kpis`,
			method: 'get',
			params,
		});
	}

	public getProjectList(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/list`,
			method: 'get',
			params,
		});
	}

	public getProjectListV2(params?: GetProjectListParams): Observable<Response<Project[]>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/list/v2`,
			method: 'get',
			params,
		});
	}

	public getProjectDetails(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/detail`,
			method: 'get',
			params,
		});
	}

	public getProjectKpis(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/projectKpis_new`,
			method: 'get',
			params,
		});
	}

	public getBillingTerms(): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/billingTerms`,
			method: 'get',
		});
	}

	public getProjectEngineers(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/projectEngineers`,
			method: 'get',
			params,
		});
	}

	public getProjectManagers(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/projectManagers`,
			method: 'get',
			params,
		});
	}

	public getSemaphore(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/semaphore`,
			method: 'get',
			params,
		});
	}

	public setGeneralStatus(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/generalStatus`,
			method: 'post',
		}, data);
	}

	public getGeneralStatus(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/generalStatus`,
			method: 'get',
			params,
		});
	}

	public getGeneralStatusRemove(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/generalStatus/remove`,
			method: 'get',
			params,
		});
	}

	public getGeneralHistory(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/generalStatus/list`,
			method: 'get',
			params,
		});
	}

	public getProjectTeam(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/team`,
			method: 'get',
			params,
		});
	}

	public getSolotList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots`,
			method: 'get',
			params,
		});
	}

	public getSolotTableList(params: InputsFilterParams): Observable<Response<Solot[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots`,
			method: 'get',
			params,
		});
	}

	public getSolotsList(params: InputsFilterParams): Observable<Response<Solot[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/v2`,
			method: 'get',
			params,
		});
	}

	public getUfSolotsResume(params: InputsFilterParams): Observable<Response<UfSolotsResume>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/ufIndicators`,
			method: 'get',
			params,
		});
	}

	public getSolotNotesList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots/notes`,
			method: 'get',
			params,
		});
	}

	public setSolotNote(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots/notes`,
			method: 'post',
		}, data);
	}

	public setSolotNoteV2(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots/notes/v2`,
			method: 'post',
		}, data);
	}

	public getSolotTaskList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots/tasks`,
			method: 'get',
			params,
		});
	}

	public getSolotTaskListNew(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots/tasks_new`,
			method: 'get',
			params,
		});
	}

	public getClosureChangeCauseList(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots/closureChangeCause`,
			method: 'get',
			params,
		});
	}

	public getClosureProjectionList(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/closureProjection`,
			method: 'get',
			params,
		});
	}

	public getPivotMonthList(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/pivotMonth`,
			method: 'get',
			params,
		});
	}

	public getPivotMonthDetail(params?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/pivotMonthDetail`,
			method: 'get',
			params,
		});
	}

	public setClosureMonthInfo(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/pivotMonth`,
			method: 'put',
		}, data);
	}

	public setClosureMonthInfoV2(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/pivotMonth/v2`,
			method: 'put',
		}, data);
	}

	public setAddClosureMonth(solotIds: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/pivotMonthAdd`,
			method: 'put',
		}, solotIds);
	}

	public setRemoveClosureMonth(solotIds: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/pivotMonthRemove`,
			method: 'put',
		}, solotIds);
	}

	public runClosureMonth(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/closePivotMonth`,
			method: 'put',
		}, data);
	}

	public setClosureProjectionFlag(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/closureProjectionFlag`,
			method: 'post',
		}, data);
	}

	public runClosureMonthV2(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/closePivotMonth/v2`,
			method: 'put',
		}, data);
	}

	public runClosingMonth(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/closingMonth`,
			method: 'put',
		}, data);
	}

	public setProjectSolotClosureProjection(data?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/solots/closureProjection`,
			method: 'post',
		}, data);
	}

	public setSolotClosureProjection(data?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/closureProjection`,
			method: 'post',
		}, data);
	}

	public setSolotClosureProjectionV2(data?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/closureProjection/v2`,
			method: 'post',
		}, data);
	}

	public setProjectTeam(details: ProjectDetails): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/detail`,
			method: 'put',
		}, details);
	}

	public updateProject(project: Project): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects`,
			method: 'put',
		}, project);
	}

	public getTypeFiles(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/fileTypes`,
			method: 'get',
			params,
		});
	}

	public getFilesList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/files`,
			method: 'get',
			params,
		});
	}

	public getDonwload(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/download`,
			method: 'get',
			params,
		});
	}

	public getDeleteFileServer(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/deleteFileServer`,
			method: 'get',
			params,
		});
	}

	public getSolotsStatusEtl(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/solotsStatusEtl`,
			method: 'get',
			params,
		});
	}

	public closeProject(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/closeProject`,
			method: 'put',
		}, data);
	}

	public getKpiList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots/kpis`,
			method: 'get',
			params,
		});
	}

	public getTasksList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/tasks`,
			method: 'get',
			params,
		});
	}

	public updateManagementType(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/managementType`,
			method: 'put',
		}, data);
	}

	public updateBuilding(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/building`,
			method: 'put',
		}, data);
	}

	public removeProjectFile(data?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/file/remove`,
			method: 'post',
		}, data);
	}

	public getUsersListByConcept(params: UserListByConceptParams): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/users`,
			method: 'get',
			params,
		});
	}

	public getUsersListByRole(params: { roleId: number }): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/userByRole`,
			method: 'get',
			params,
		});
	}

	public getProjectClassificationTypeList(): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/managementType`,
			method: 'get',
		});
	}

	public getProjectOnTimeStatusList(): Observable<Response<FilterConcept[]>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/onTime`,
			method: 'get',
		});
	}

	public getUserContactInfo(params: { userId: string }): Observable<Response<UserContactInterface>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/user`,
			method: 'get',
			params,
		});
	}

	public getAreasList(): Observable<Response<UserContactInterface>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/areas`,
			method: 'get'
		});
	}

	public getResponsiblesList(): Observable<Response<UserContactInterface>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/responsibles`,
			method: 'get'
		});
	}

	public getClassificationsList(): Observable<Response<UserContactInterface>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/classifications`,
			method: 'get'
		});
	}

	public getSolotTypesList(): Observable<Response<UserContactInterface>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/solotTypes`,
			method: 'get'
		});
	}

	public getServiceTypesList(): Observable<Response<UserContactInterface>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/serviceTypes`,
			method: 'get'
		});
	}

	public getSolotDisconnectionList(params: InputsFilterParams): Observable<Response<Solot[]>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/solot-disconnection`,
			method: 'get',
			params
		});
	}

	public getSolotDisconnectionTaskList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/tasks`,
			method: 'get',
			params,
		});
	}

	public getSolotDisconnectionNotesList(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/notes`,
			method: 'get',
			params,
		});
	}

	public setSolotAreaClassification(data?: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/areaClassification`,
			method: 'post',
		}, data);
	}

	public setSolotDisconnectionNote(data: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/notes`,
			method: 'post',
		}, data);
	}

	public getDonwloadDisconnection(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/download`,
			method: 'get',
			params,
		});
	}

	public getDonwloadDisconnectionServices(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/downloadServices`,
			method: 'get',
			params,
		});
	}

	public getSolotsDisconnectionStatusEtl(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/solots-disconnection/solotsStatusEtl`,
			method: 'get',
			params,
		});
	}

	public setMinute(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/minutes/addMinute`,
			method: 'post'
		}, params);
	}

	public updateMinute(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/minutes/upMinute`,
			method: 'post'
		}, params);
	}

	public deleteMinute(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/minutes/delMinute`,
			method: 'post'
		}, params);
	}

	public getMinute(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/minutes`,
			method: 'get',
			params
		});
	}
	public getTaskExpenses(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/projects/taskExpenses`,
			method: 'get',
			params
		});
	}
	public downloadMinute(params?): Observable<any> {
		return this.Helpers.resources({
			url: `${this.api}/pdf/downloadMinute`,
			method: 'get',
			params
		}, params);
	}
}
