// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	baseUrl: 'https://claro-qa-api.blixter.cl/api/claro-api',
	host: '0.0.0.0',
	loginSessionTime: 30, // time in minutes
	sessionWarningTime: 0.5, // time in minutes
	socketUrl: 'https://claro-qa-api.blixter.cl'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
