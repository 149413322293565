import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { FileUploader } from 'ng2-file-upload';
import swal from 'sweetalert2';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { NotifyType } from '../../helpers/helpers.services';

@Component({
	selector: 'app-project-file',
	templateUrl: './project-file.component.html',
	styleUrls: ['./project-file.component.scss']
})
export class ProjectFileComponent implements OnInit {
	@Input() projectId: number;
	@Input() projectCode: string;
	@Input() url: string;

	@Output() afterAddingFile: EventEmitter<any> = new EventEmitter<any>();
	@Output() completeItem: EventEmitter<any> = new EventEmitter<any>();

	statusList: FilterConcept[];
	typeFileList: any[];
	fileStatusId: any;
	projectTypeFileId: any;
	projectFilesList: any[];
	uploader: FileUploader;
	errorMessage: NotifyType = {
		title: 'Error',
		message: 'Ocurrio un error inesperado!',
		type: 'danger',
	};

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
	) { }

	ngOnInit() {
		const params = { projectId: this.projectId };
		const maxFileSize = 10 * 1024 * 1024;

		this.getStatusList(params);
		this.uploader = new FileUploader({
			url: this.url,
			headers: [
				{ name: 'x-access-token', value: localStorage.getItem('userAuth') }
			],
			itemAlias: 'claro',
			disableMultipart: false,
			maxFileSize
		});

		this.uploader.onBuildItemForm = (item, form) => {
			form.append('projectId', this.projectId);
			form.append('projectCode', this.projectCode);
			form.append('fileTypeId', this.projectTypeFileId);
		};

		this.uploader.onWhenAddingFileFailed = (item, filter) => {
			let message = '';
			console.log('item ------------> ', item);
			switch (filter.name) {
				case 'fileSize':
					message = 'El archivo cargado \"' + item.name + '\" pesa ' + this.formatBytes(item.size) +
					', este excede el límite definido ' + this.formatBytes(maxFileSize);
					break;
				default:
					message = 'Error al intentar cargar el archivo ' + item.name;
					break;
			}
			// this.uploader.removeFromQueue(item);
			alert(message);
			this.uploader.removeFromQueue(this.uploader.queue[0]);
		};

		this.uploader.onAfterAddingFile = (file) => {
			file.withCredentials = false;
		};

		this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			const res = JSON.parse(response);

			if (res.status) {
				this.cleanUploadFileForm();
				this.completeItem.emit({
					response: res,
					item,
					projectId: this.projectId,
				});
				swal.fire({
					icon: 'success',
					title: 'Guardado',
					text: 'Archivo guardado exitosamente!'
				});
			} else {
				this.completeItem.emit({
					response: res,
					projectId: this.projectId,
				});
				const err = Object.assign(this.errorMessage, { message: res.message });

				this.uploader.queue[0].isSuccess = false;
				this.uploader.queue[0].isCancel = false;
				this.uploader.queue[0].isError = true;
				this.helpers.notify(err);
			}
		};
	}

	cleanUploadFileForm() {
		this.projectTypeFileId = '';
		this.fileStatusId = '';
		this.uploader.queue = [];
	}

	getStatusList(params) {
		this.api.getStatuses(params).subscribe((response) => {
			if (response.status) {
				this.statusList = response.data || [];
			}
		});
	}

	getTypeFilesList(statusId) {
		this.typeFileList = [];
		if (statusId != null) {
			this.api.getTypeFiles({ statusId }).subscribe((response) => {
				if (response.status) {
					this.typeFileList = response.data || [];
				}
			});
		}
	}

	onFileSelect(): void {
		this.uploader.queue = [this.uploader.queue.pop()];
	}

	formatBytes(bytes, decimals?) {
		if (bytes == 0) { return '0 Bytes'; }

		const k = 1024;
		const dm = decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

}
