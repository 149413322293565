import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import {
	EntitySolotFilter,
	InputParameters,
	InputsFilterData,
	SolotInputsList,
	InputsFilterParams
} from 'src/app/interfaces/filters-inputs.interface';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';

moment.locale('es');

export interface DateRangePickerSelected {
	startDate: moment.Moment;
	endDate: moment.Moment;
}
@Component({
	selector: 'app-entity-solots-filters-three',
	templateUrl: './entity-solots-filters-three.component.html',
	styleUrls: ['./entity-solots-filters-three.component.scss']
})
export class EntitySolotsFiltersThreeComponent implements OnInit, OnDestroy {
	@Input() inputList: SolotInputsList;
	@Output() filtering = new EventEmitter<EntitySolotFilter>();

	isOpen = false;
	inputsData: InputsFilterData;
	params: InputsFilterParams;
	dateRangeOntime: any;
	dateRangePfv: any;
	ranges: { [name: string]: moment.Moment[] } = {
		Hoy: [moment(), moment()],
		Ayer: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Menos de 7 días': [moment().subtract(6, 'days'), moment()],
		'Entre 7 y 14 días': [moment().subtract(13, 'days'), moment().subtract(6, 'days')],
		'Entre 15 y 30 días': [moment().subtract(29, 'days'), moment().subtract(14, 'days')],
	};

	locale = {
		firstDay: 1,
		daysOfWeek: moment.weekdaysMin(),
    	monthNames: moment.monthsShort(),
	}

	buildingSubscription: Subscription;
	managerSubscription: Subscription;
	engineerSubscription: Subscription;
	businessSubscription: Subscription;
	salesSubscription: Subscription;
	edacSubscription: Subscription;
	responsibleSubscription: Subscription;
	statusesSubscription: Subscription;
	subTypesSubscription: Subscription;
	projectionSubscription: Subscription;
	pfvStatusSubscription: Subscription;
	onTimeStatusSubscription: Subscription;

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
	) { }

	ngOnInit() {
		this.inputsData = { };
		this.params = { };
	}

	ngOnDestroy() {
	}

	notifyInputChange(inputName: InputParameters) {
		this.filtering.emit({
			inputsFilterData: this.inputsData,
			inputsFilterParams: this.params,
			inputChanged: inputName
		});
	}

	onAreaChange(): void {
		this.params.areaId = this.helpers.prepareArrayToSendParameterId(this.inputsData.areaSelected);
		this.notifyInputChange(InputParameters.AREAS);
	}

	onManagersChange(): void {
		this.params.managerId = this.helpers.prepareArrayToSendParameterId(this.inputsData.managersSelected);
		this.notifyInputChange(InputParameters.MANAGERS);
	}

	onClassificationsChange(): void {
		this.params.classificationId = this.helpers.prepareArrayToSendParameterId(this.inputsData.classificationSelected);
		this.notifyInputChange(InputParameters.TYPE);
	}

	onServiceTypeChange(): void {
		this.params.serviceTypeId = this.helpers.prepareArrayToSendParameterId(this.inputsData.serviceTypeSelected);
		this.notifyInputChange(InputParameters.SERVICE_TYPE);
	}

	onSolotTypeChange(): void {
		this.params.solotTypeId = this.helpers.prepareArrayToSendParameterId(this.inputsData.solotTypeSelected);
		this.notifyInputChange(InputParameters.SOLOT_TYPE);
	}

	onChooseDate({ startDate, endDate }: DateRangePickerSelected) {
		this.params.startDate = startDate && endDate ? startDate.format('YYYY-MM-DD') : null;
		this.params.endDate = startDate && endDate ? endDate.format('YYYY-MM-DD') : null;
		this.notifyInputChange(InputParameters.DATE_RANGE);
	}

	onChooseDateClosing({ startDate, endDate }: DateRangePickerSelected) {
		this.params.startDateClosing = startDate && endDate ? startDate.format('YYYY-MM-DD') : null;
		this.params.endDateClosing = startDate && endDate ? endDate.format('YYYY-MM-DD') : null;
		this.notifyInputChange(InputParameters.DATE_RANGE_CLOSING);
	}
}
