import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { FilterConcept } from '../../../interfaces/projects.interfaces';

export type ClassNameType = string | { [propName: string]: boolean };
export interface LabelToggleInputEditClassType {
	className?: ClassNameType;
	labelClass?: ClassNameType;
	inputClass?: ClassNameType;
}

export type InputType = 'text' | 'number' | 'select' | 'date' | 'textarea';
export type MapComponentsByInputType = {
	[propName in InputType]: string;
};
export interface LabelToggleInputEditEmitterType {
	inputName?: string;
	value?: string | number | FilterConcept | FilterConcept[];
}
@Component({
	selector: 'app-label-toggle-input-edit',
	templateUrl: './label-toggle-input-edit.component.html',
	styleUrls: ['./label-toggle-input-edit.component.scss']
})
export class LabelToggleInputEditComponent implements OnInit {
	@Input() value: string | number | FilterConcept[] | FilterConcept;
	@Input() valueDate?: string;
	@Input() labelEmptyText: string;
	@Input() items: FilterConcept[];
	@Input() icon: string;
	@Input() inputName: string;
	@Input() inputId: string | number;
	@Input() inputType: InputType;
	@Input() labelAppendText: string;
	@Input() permissionName?: string;
	@Input() classNames?: LabelToggleInputEditClassType;

	@Output() inputSubmit = new EventEmitter<LabelToggleInputEditEmitterType>();
	@Output() selectSubmit = new EventEmitter<LabelToggleInputEditEmitterType>();
	@Output() selectList = new EventEmitter<LabelToggleInputEditEmitterType>();

	editing: boolean;
	mapComponentByInputType: MapComponentsByInputType = {
		text: 'input',
		number: 'input',
		date: 'date',
		select: 'select',
		textarea: 'textarea',
	};

	constructor(
		private helper: HelpersServices
	) { }

	ngOnInit() {
		this.editing = false;
		this.classNames = this.classNames || { };
	}

	onInputSubmit(answer: LabelToggleInputEditEmitterType) {
		this.editing = false;

		if (answer.inputName) {
			this.inputSubmit.emit(answer);
		}
	}

	onSelectSubmit(answer: LabelToggleInputEditEmitterType) {
		this.editing = false;

		if (answer.inputName) {
			this.selectSubmit.emit(answer);
		}
	}

	editLabel() {
		if (!this.permissionName || this.helper.hasPermission(this.permissionName)) {
			this.selectList.emit();
			this.editing = !this.editing;
		} else {
			this.editing = false;
			this.helper.notify({
				title: 'Necesita permisos adicionales',
				message: 'Contacte al administrador',
				type: 'warning',
				options: { timeOut: 3000 },
			});
		}
	}

}
