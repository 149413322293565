import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DxVectorMapModule } from 'devextreme-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared/shared.module';
import { MenuComponent } from './menu/menu.component';
import { ListComponent } from './list/list.component';
import { ListItemComponent } from './list/list-item/list-item.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { ComponentsServices } from './components.services';
import { SolotsTableComponent } from './solots-table/solots-table.component';
import { ProjectFileComponent } from './project-file/project-file.component';
import { SolotFileComponent } from './solot-file/solot-file.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SolotCardComponent } from './solot-card/solot-card.component';
import { DeliverySolotCardComponent } from './delivery-solot-card/delivery-solot-card.component';
import { DeliveryDocumentComponent } from './delivery-document/delivery-document.component';
import { SolotCard2Component } from './solot-card2/solot-card2.component';
import { SolotCard3Component } from './solot-card3/solot-card3.component';
import { SolotCard4Component } from './solot-card4/solot-card4.component';
import { SolotCard5Component } from './solot-card5/solot-card5.component';
import { SolotCardDisconnectionComponent } from './solot-disconnection-card/solot-disconnection-card.component';
import { SolotsCardContainerComponent } from './solots-card-container/solots-card-container.component';
import { SolotHeaderContainerComponent } from './solots-card-container/solot-header-container/solot-header-container.component';
import { SolotsDisconnectionCardContainerComponent } from './solots-disconnection-card-container/solots-disconnection-card-container.component';
import { SolotDisconnectionHeaderContainerComponent } from './solots-disconnection-card-container/solot-disconnection-header-container/solot-disconnection-header-container.component';
import { SolotsFilterComponent } from './solots-filter/solots-filter.component';
import { SolotsFilterTwoComponent } from './solots-filter-two/solots-filter-two.component';
import { SolotsFilterThreeComponent } from './solots-filter-three/solots-filter-three.component';
import { QuickSolotsFiltersComponent } from './solots-filter/quick-solots-filters/quick-solots-filters.component';
import { EntitySolotsFiltersComponent } from './solots-filter/entity-solots-filters/entity-solots-filters.component';
import { QuickSolotsFiltersTwoComponent } from './solots-filter-two/quick-solots-filters-two/quick-solots-filters-two.component';
import { EntitySolotsFiltersTwoComponent } from './solots-filter-two/entity-solots-filters-two/entity-solots-filters-two.component';
import { QuickSolotsFiltersThreeComponent } from './solots-filter-three/quick-solots-filters-three/quick-solots-filters-three.component';
import { EntitySolotsFiltersThreeComponent } from './solots-filter-three/entity-solots-filters-three/entity-solots-filters-three.component';
import { SearchComponent } from './search/search.component';
import { SelectedFiltersComponent } from './selected-filters/selected-filters.component';
import { KpiContainerComponent } from './kpi-container/kpi-container.component';
import { KpiItemComponent } from './kpi-container/kpi-group/kpi-item/kpi-item.component';
import { KpiGroupComponent } from './kpi-container/kpi-group/kpi-group.component';
import { SolotActionControlComponent } from './solot-card4/solot-action-control/solot-action-control.component';
import { Solot5ActionControlComponent } from './solot-card5/solot5-action-control/solot5-action-control.component';
import { SolotDisconnectionActionControlComponent } from './solot-disconnection-card/solot-disconnection-action-control/solot-disconnection-action-control.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { ProjectCardContainerComponent } from './project-card-container/project-card-container.component';
import { ProjectsFilterComponent } from './projects-filter/projects-filter.component';
import { EntitiesFiltersComponent } from './projects-filter/entities-filters/entities-filters.component';
import { SecondaryFiltersComponent } from './projects-filter/secondary-filters/secondary-filters.component';
import { FieldsLabelComponent } from './atoms/fields-label/fields-label.component';
import { InputEditComponent } from './atoms/input-edit/input-edit.component';
import { LabelToggleInputEditComponent } from './molecules/label-toggle-input-edit/label-toggle-input-edit.component';
import { SelectEditComponent } from './atoms/select-edit/select-edit.component';
import { InputDatePickerComponent } from './atoms/input-date-picker/input-date-picker.component';
import { InputTextareaComponent } from './atoms/input-textarea/input-textarea.component';
import { PersonalInfoComponent } from './molecules/personal-info/personal-info.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CollapseModule.forRoot(),
		DxVectorMapModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		SharedModule,
		FileUploadModule,
	],
	declarations: [
		FooterComponent,
		NavbarComponent,
		SidebarComponent,
		MenuComponent,
		ListComponent,
		ListItemComponent,
		SkeletonComponent,
		SolotsTableComponent,
		ProjectFileComponent,
		SolotFileComponent,
		SolotCardComponent,
		DeliverySolotCardComponent,
		DeliveryDocumentComponent,
		SolotCard2Component,
		SolotCard3Component,
		SolotCard4Component,
		SolotCard5Component,
		SolotCardDisconnectionComponent,
		SolotsCardContainerComponent,
		SolotHeaderContainerComponent,
		SolotsDisconnectionCardContainerComponent,
		SolotDisconnectionHeaderContainerComponent,
		SolotsFilterComponent,
		SolotsFilterTwoComponent,
		SolotsFilterThreeComponent,
		QuickSolotsFiltersComponent,
		EntitySolotsFiltersComponent,
		QuickSolotsFiltersTwoComponent,
		EntitySolotsFiltersTwoComponent,
		QuickSolotsFiltersThreeComponent,
		EntitySolotsFiltersThreeComponent,
		SearchComponent,
		SelectedFiltersComponent,
		KpiContainerComponent,
		KpiItemComponent,
		KpiGroupComponent,
		SolotActionControlComponent,
		Solot5ActionControlComponent,
		SolotDisconnectionActionControlComponent,
		ProjectCardComponent,
		ProjectCardContainerComponent,
		ProjectsFilterComponent,
		EntitiesFiltersComponent,
		SecondaryFiltersComponent,
		FieldsLabelComponent,
		InputEditComponent,
		LabelToggleInputEditComponent,
		SelectEditComponent,
		InputDatePickerComponent,
		InputTextareaComponent,
		PersonalInfoComponent,
	],
	exports: [
		FooterComponent,
		NavbarComponent,
		SidebarComponent,
		ListComponent,
		ListItemComponent,
		SkeletonComponent,
		SolotsTableComponent,
		ProjectFileComponent,
		SolotFileComponent,
		SolotCardComponent,
		DeliverySolotCardComponent,
		DeliveryDocumentComponent,
		SolotCard2Component,
		SolotCard3Component,
		SolotCard4Component,
		SolotCard5Component,
		SolotCardDisconnectionComponent,
		SolotsCardContainerComponent,
		SolotsDisconnectionCardContainerComponent,
		SolotsFilterComponent,
		SolotsFilterTwoComponent,
		SolotsFilterThreeComponent,
		SearchComponent,
		SelectedFiltersComponent,
		QuickSolotsFiltersComponent,
		QuickSolotsFiltersTwoComponent,
		QuickSolotsFiltersThreeComponent,
		KpiContainerComponent,
		ProjectCardComponent,
		ProjectCardContainerComponent,
		ProjectsFilterComponent,
		EntitiesFiltersComponent,
		FieldsLabelComponent,
		InputEditComponent,
		LabelToggleInputEditComponent,
		SelectEditComponent,
		InputDatePickerComponent,
		InputTextareaComponent,
		PersonalInfoComponent,
	],
	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		ComponentsServices,
	]
})
export class ComponentsModule { }
