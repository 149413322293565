import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HelpersServices, NotifyType } from 'src/app/helpers/helpers.services';
import * as moment from 'moment';
import { Binnacles, Solot, SolotTask } from 'src/app/interfaces/project-solot.interface';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-solot-disconnection-action-control',
	templateUrl: './solot-disconnection-action-control.component.html',
	styleUrls: ['./solot-disconnection-action-control.component.scss']
})
export class SolotDisconnectionActionControlComponent implements OnInit {
	@Input() solot: Solot;
	@Input() projectedClosingMonthList: FilterConcept[] = [];
	@Input() closureChangeCauseList: FilterConcept[] = [];
	@Input() savingTableChanges: boolean;
	@Input() buttonDisabled?: boolean;
	@Input() inputList;

	@Output() save = new EventEmitter<Solot>();

	isEditing = false;
	savingBinnacles: boolean;
	newBinnacles: string;
	solotNotesList: Binnacles[] = [];
	taskStatusList: SolotTask[] = [];
	urlUpload = `${environment.baseUrl}/projects/files`;
	areaSelected = { };
	classificationSelected = { };
	btnTaskStatus = false;
	btnBinnacle = false;
	inputSelect = {
		areaSelected : null,
		classificationSelected: null
	};
	errorMessage: NotifyType = {
		title: 'Error',
		message: 'Ocurrió un error inesperado!',
		type: 'danger',
	};

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
		private modalService: NgbModal,
		config: NgbModalConfig,
	) {
		config.backdrop = 'static';
	}

	ngOnInit(): void {
		this.solot.areaSelected = this.solot.area;
		this.solot.classificationSelected = this.solot.classification;
	}

	onCancel(): void {
		this.isEditing = false;
	}

	onArea() {
		this.inputSelect.areaSelected = this.solot.areaSelected;
	}

	onClassification() {
		this.inputSelect.classificationSelected = this.solot.classificationSelected;
	}

	onSave(): void {
		this.solot.areaSelected = this.inputSelect.areaSelected;
		this.solot.classificationSelected = this.inputSelect.classificationSelected;
		this.save.emit(this.solot);
		this.isEditing = false;
	}

	openModalTaskStatusDescriptions(content) {
		this.btnTaskStatus = true;
		this.buttonDisabled = true;
		const modalConfig = {
			size: 'lg',
			centered: true,
		};

		this.taskStatusList = [];
		this.api.getSolotDisconnectionTaskList({ solotId: this.solot.solotId }).subscribe(
			response => {
				if (response.status) {
					this.taskStatusList = response.data.map((task: SolotTask) => {
						task.startDate = task.startDate ? moment(task.startDate).format('DD/MM/YYYY HH:mm') : '-';
						task.commitmentDate = task.commitmentDate ? moment(task.commitmentDate).format('DD/MM/YYYY HH:mm') : '-';
						task.startSis = task.startSis ? moment(task.startSis).format('DD/MM/YYYY HH:mm') : '-';
						task.endSis = task.endSis ? moment(task.endSis).format('DD/MM/YYYY HH:mm') : '-';
						return task;
					});
					this.btnTaskStatus = false;
					this.buttonDisabled = false;
					this.modalService.open(content, modalConfig);
				}
			},
			error => this.helpers.handleErrors(error),
		);
	}

	openViewBinnacles(content): void {
		this.btnBinnacle = true;
		this.buttonDisabled = true;
		const modalConfig = {
			size: 'lg',
			centered: true,
		};

		this.getSolotDisconnectionNotesList(() => {
			this.btnBinnacle = false;
			this.buttonDisabled = false;

			this.modalService.open(content, modalConfig)
				.result.then(() => {
					this.newBinnacles = '';
				});
		});
	}

	getSolotDisconnectionNotesList(callback: () => void = () => { }): void {
		this.solotNotesList = [];
		this.api.getSolotDisconnectionNotesList({ solotId: this.solot.solotId })
			.subscribe(
				response => {
					if (response.status) {
						this.solotNotesList = response.data.map((note: Binnacles): Binnacles => {
							return note;
						});
						callback();
					} else {
						this.helpers.handleErrors(response);
					}
				},
				error => {
					this.helpers.handleErrors(error);
				}
			);
	}

	saveBinnacles(): void {
		const params = {
			solotId: this.solot.solotId,
			binnacles: this.newBinnacles,
		};

		this.savingBinnacles = true;
		this.api.setSolotDisconnectionNote([params]).subscribe(
			(response) => {
				this.savingBinnacles = false;

				if (response.status) {
					this.solot.binnacle = this.newBinnacles;
					this.getSolotDisconnectionNotesList();
					this.helpers.notify({
						title: 'Guardado',
						message: 'Datos actualizados exitosamente!',
						type: 'success',
					});
				} else {
					this.helpers.notify(this.errorMessage);
				}
			},
			(error) => {
				this.savingBinnacles = false;
				this.helpers.handleErrors(error);
			}
		);
	}
}
