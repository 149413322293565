import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KpiItem } from 'src/app/interfaces/projects.interfaces';

@Component({
	selector: 'app-kpi-item',
	templateUrl: './kpi-item.component.html',
	styleUrls: ['./kpi-item.component.scss']
})
export class KpiItemComponent implements OnInit {
	@Input() kpiItem: KpiItem;
	@Output() select = new EventEmitter<KpiItem>();

	constructor() { }

	ngOnInit() {
	}

	onKpiChange() {
		this.select.emit(this.kpiItem);
	}

}
