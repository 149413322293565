import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HelpersServices, NotifyType } from 'src/app/helpers/helpers.services';
import * as moment from 'moment';
import { LastRelevantsFacts, Solot, SolotTask } from 'src/app/interfaces/project-solot.interface';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-solot5-action-control',
	templateUrl: './solot5-action-control.component.html',
	styleUrls: ['./solot5-action-control.component.scss']
})
export class Solot5ActionControlComponent implements OnInit {
	@Input() solot: Solot;
	@Input() projectedClosingMonthList: FilterConcept[] = [];
	@Input() closureChangeCauseList: FilterConcept[] = [];
	@Input() savingTableChanges: boolean;
	@Input() buttonDisabled?: boolean;
	@Input() icon?: string;

	@Output() save = new EventEmitter<Solot>();

	closureProjected: string;
	closureChangeCause: string;
	isEditing = false;
	allowEditProjectedClosingMonth: boolean;
	allowEditClosureChangeCause: boolean;
	btnRelevantFacts: boolean;
	btnUpload: boolean;
	btnTaskStatus: boolean;
	savingLastRelevantsFacts: boolean;
	newLatestRelevantFacts: string;
	solotNotesList: LastRelevantsFacts[] = [];
	taskStatusList: SolotTask[] = [];
	isPMO = false;
	urlUpload = `${environment.baseUrl}/projects/files`;
	errorMessage: NotifyType = {
		title: 'Error',
		message: 'Ocurrió un error inesperado!',
		type: 'danger',
	};

	successMessage: NotifyType = {
		title: 'Exito',
		message: 'Cambio realizado exitosamente!',
		type: 'success',
	};

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
		private modalService: NgbModal,
		config: NgbModalConfig,
	) {
		config.backdrop = 'static';
	}

	ngOnInit(): void {
		this.closureChangeCause = this.solot.closureChangeCause;
		this.closureProjected = this.solot.closureProjected;
		this.allowEditProjectedClosingMonth = this.helpers.closureProjectedPermission(
			'closure-projection',
			this.solot.status,
			this.solot.isClosed,
			parseInt(this.solot.solotId, 10),
		);
		this.allowEditClosureChangeCause = this.helpers.closureProjectedPermission(
			'closure-change-reason',
			this.solot.status,
			this.solot.isClosed,
			parseInt(this.solot.solotId, 10),
		);
		const userAuth = this.helpers.getDecodedUser();
		this.isPMO = userAuth.user.roles.includes( 'PMO' );
	}

	onCancel(): void {
		this.isEditing = false;
		this.solot.closureChangeCause = this.closureChangeCause;
		this.solot.closureProjected = this.closureProjected;
	}

	onSave(): void {
		this.closureChangeCause = this.solot.closureChangeCause;
		this.closureProjected = this.solot.closureProjected;
		this.save.emit(this.solot);
		this.isEditing = false;
	}

	openModalTaskStatusDescriptions(content) {
		this.btnTaskStatus = true;
		this.buttonDisabled = true;
		const modalConfig = {
			size: 'lg',
			centered: true,
		};

		this.taskStatusList = [];
		this.api.getSolotTaskListNew({ solotId: this.solot.solotId }).subscribe(
			response => {
				if (response.status) {
					this.taskStatusList = response.data.map((task: SolotTask) => {
						task.startDate = task.startDate ? moment(task.startDate).format('DD/MM/YYYY HH:mm') : '-';
						task.commitmentDate = task.commitmentDate ? moment(task.commitmentDate).format('DD/MM/YYYY HH:mm') : '-';
						task.ltExcecutionDate = task.ltExcecutionDate ? moment(task.ltExcecutionDate).format('DD/MM/YYYY HH:mm') : '-';

						return task;
					});
					this.btnTaskStatus = false;
					this.buttonDisabled = false;
					this.modalService.open(content, modalConfig);
				}
			},
			error => this.helpers.handleErrors(error),
		);
	}

	uploadSolotsItem(content, projectId): void {
		this.btnUpload = true;
		this.buttonDisabled = true;
		const modalConfig = {
			size: 'xl',
			centered: true,
		};

		this.modalService.open(content, modalConfig);
		this.btnUpload = false;
		this.buttonDisabled = false;
	}

	getSolotNotesList(callback: () => void = () => { }): void {
		this.solotNotesList = [];
		this.api.getSolotNotesList({ solotId: this.solot.solotId })
			.subscribe(
				response => {
					if (response.status) {
						this.solotNotesList = response.data.map((note: LastRelevantsFacts): LastRelevantsFacts => {
							note.updatedAt = note.updatedAt ? moment(note.updatedAt).format('DD/MM/YYYY HH:mm') : '-';
							return note;
						});
						callback();
					} else {
						this.helpers.handleErrors(response);
					}
				},
				error => {
					this.helpers.handleErrors(error);
				}
			);
	}

	openViewRelevantFacts(content): void {
		this.btnRelevantFacts = true;
		this.buttonDisabled = true;
		const modalConfig = {
			size: 'lg',
			centered: true,
		};

		this.getSolotNotesList(() => {
			this.btnRelevantFacts = false;
			this.buttonDisabled = false;
			this.modalService.open(content, modalConfig)
				.result.then(() => {
					this.newLatestRelevantFacts = '';
				});
		});
	}

	saveRelevantFacts(): void {
		const params = {
			solotId: this.solot.solotId,
			lastRelevantsFacts: this.newLatestRelevantFacts,
		};
		this.savingLastRelevantsFacts = true;
		this.api.setSolotNote([params]).subscribe(
			(response) => {
				this.savingLastRelevantsFacts = false;

				if (response.status) {
					this.solot.lastRelevantsFacts = this.newLatestRelevantFacts;
					this.getSolotNotesList();
					this.helpers.notify({
						title: 'Guardado',
						message: 'Datos actualizados exitosamente!',
						type: 'success',
					});
				} else {
					this.helpers.notify(this.errorMessage);
				}
			},
			(error) => {
				this.savingLastRelevantsFacts = false;
				this.helpers.handleErrors(error);
			}
		);
	}

	saveCPM(): void {
		const params = {
			solotId: this.solot.solotId,
		};
		this.api.setClosureProjectionFlag(params).subscribe((response) => {
			if (response.status) {
				this.helpers.notify(this.successMessage);
			}
		});
	}

}
