import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { HasDisableType } from 'src/app/interfaces/directives.interface';
import { ClassNameType, LabelToggleInputEditEmitterType } from '../../molecules/label-toggle-input-edit/label-toggle-input-edit.component';

@Component({
	selector: 'app-input-textarea',
	templateUrl: './input-textarea.component.html',
	styleUrls: ['./input-textarea.component.scss']
})
export class InputTextareaComponent implements OnInit {
	@Input() inputName?: string;
	@Input() inputId?: string | number;
	@Input() inputType?: 'text' | 'number';
	@Input() value?: string;
	@Input() placeholder?: string;
	@Input() className?: string | ClassNameType;
	@Input() disabled?: boolean;

	@Output() action = new EventEmitter<LabelToggleInputEditEmitterType>();
	originalValue: string;

	constructor(private helpers: HelpersServices) { }

	ngOnInit() {
		this.originalValue = this.value;
		this.inputId = this.helpers.guid();
		this.inputName = this.inputName || 'input';
		this.inputType = this.inputType || 'text';
		this.value = this.value || '';
	}

	onAction(answer: boolean) {
		if (answer) {
			this.originalValue = this.value;
			this.action.emit({
				inputName: this.inputName,
				value: this.value,
			});
		} else {
			this.value = this.originalValue;
			this.action.emit({
				inputName: null,
				value: null,
			});
		}
	}
}
