import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RouteInfo } from '../../interfaces/menu.interfaces';
import { Router } from '@angular/router';
import { HelpersServices } from '../../helpers/helpers.services';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {
	@Input() items: RouteInfo[];
	@Input() parent?: RouteInfo;
	@Input() level?: number;
	viewSupport;

	public navClass: string;
	public isCollapsed = true;

	constructor(
		private router: Router,
		private helpers: HelpersServices,
	) { }

	ngOnInit() {
		this.level = this.level || 0;
		this.navClass = this.getNavClass(this.level);
		this.router.events.subscribe(() => {
			this.isCollapsed = true;
		});

		const userAuth = this.helpers.getDecodedUser();
		this.viewSupport = userAuth && userAuth.user.roles.includes( 'PMO' );
	}

	ngOnChanges(changes) {
		if (changes && changes.level) {
			this.ngOnInit();
		}
	}

	getNavClass(level) {
		return level == 0 ? 'navbar-nav' : (level == 1 ? 'nav nav-sm flex-column' : 'nav');
	}

}
