import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FilterConcept, Project } from 'src/app/interfaces/projects.interfaces';
import { HelpersServices } from '../../helpers/helpers.services';

@Component({
	selector: 'app-delivery-document',
	templateUrl: './delivery-document.component.html',
	styleUrls: ['./delivery-document.component.scss']
})
export class DeliveryDocumentComponent implements OnInit {
	@Input() solot;
	constructor(
		private helpers: HelpersServices
	) { }

	ngOnInit() {
	}

}
