import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { NewUserData, UserInfo } from '../../interfaces/users.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelpersServices } from '../../helpers/helpers.services';
import { Paginator } from 'src/app/interfaces/paginator.interface';
import { Response } from 'src/app/interfaces/api-response.interface';

export interface GetUserListParameters extends Paginator {
	txt?: string;
}

@Injectable({
	providedIn: 'root'
})
export class AdminpanelService {
	api: string;
	headers: any;

	constructor(
		private http: HttpClient,
		private Helpers: HelpersServices,
	) {
		this.api = environment.baseUrl;
		this.headers = new HttpHeaders({
			'x-access-token': localStorage.getItem('userAuth'),
		});
	}

	public getUsersList(params?: GetUserListParameters): Observable<Response<UserInfo[]>> {
		return this.Helpers.resources({
			url: `${this.api}/users`,
			method: 'get',
			params,
		});
	}

	public getUsers(): Observable<any> {
		return this.http.get(`${this.api}/users`, {
			headers: this.headers
		});
	}

	public updateUser(user: UserInfo): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/users/detail`,
			method: 'put'
		}, user);
	}

	public createUser(user: NewUserData): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/users/detail`,
			method: 'post'
		}, user);
	}

	public getRoles(): Observable<any> {
		return this.http.get(`${this.api}/users/roles`, {
			headers: this.headers
		});
	}

	public getGroups(): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/users/dataGroups`,
			method: 'get',
		});
	}

	public getBusinessExecutiveList(): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/commons/businessExecutiveList`,
			method: 'get',
		});
	}

	public createGroup(data: any): Observable<any> {
		return this.Helpers.resources({
			url: `${this.api}/users/dataGroups`,
			method: 'post',
		}, data);
	}

	public updateGroup(data: any): Observable<any> {
		return this.Helpers.resources({
			url: `${this.api}/users/dataGroups`,
			method: 'put',
		}, data);
	}

	public getConceptTypeList(): Observable<any> {
		return this.http.get(`${this.api}/users/dataGroups/concepts`, {
			headers: this.headers
		});
	}

	public updateGroupConcepts(data: any): Observable<any> {
		return this.Helpers.resources({
			url: `${this.api}/users/dataGroups/concepts`,
			method: 'post',
		}, data);
	}

	public getConceptsList(params: any): Observable<any> {
		return this.Helpers.resources({
			url: `${this.api}/users/dataGroups/concepts`,
			method: 'get',
			params,
		});
	}

	public saveRoleGroups(roles: any): Observable<any> {
		return this.http.post(`${this.api}/users/rolesGroups`, roles, {
			headers: this.headers
		});
	}

	public getPermissions(role?: any): Observable<any> {
		role = role || '';
		return this.http.get(`${this.api}/users/roles/permissions/${role}`, {
			headers: this.headers
		});
	}

	public saveRolePermissions(data: any): Observable<any> {
		return this.http.post(`${this.api}/users/roles/permissions/`, data, {
			headers: this.headers
		});
	}

	public createRole(data: any): Observable<any> {
		return this.http.post(`${this.api}/users/roles`, data, {
			headers: this.headers
		});
	}

	public updateRole(data: any): Observable<any> {
		return this.http.put(`${this.api}/users/roles`, data, {
			headers: this.headers
		});
	}

	public getIntegrationList(): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/users/integrations`,
			method: 'get',
		});
	}

	public updateIntegration(params?: GetUserListParameters): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/users/integrations/upIntegration`,
			method: 'post',
			params,
		}, params);
	}

	public updateIntegrationLast(params?): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/users/integrations/upIntegrationLast`,
			method: 'post',
			params,
		}, params);
	}

	public getStatusEtl(params: any): Observable<Response<any>> {
		return this.Helpers.resources({
			url: `${this.api}/users/integrations/statusEtl`,
			method: 'get',
			params,
		});
	}
}
