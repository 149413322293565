import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { HelpersServices } from '../helpers/helpers.services';
import * as $ from 'jquery';

declare global {
		interface Window {
				WebChat: any;
		}
}

const FB = window.WebChat; // ok now

@Directive({
	// tslint:disable-next-line: directive-selector
	selector: '[bot]'
})

export class BotDirective implements OnInit {

	constructor(
		private el: ElementRef,
		private helpers: HelpersServices,
	) { }

	@Input() bot: string;

	ngOnInit() {
		const userAuth = this.helpers.getDecodedUser();

		if (userAuth) {
			const { user: { permissions: userPermissions }} = userAuth;

			// if (!(userPermissions && userPermissions[0] && userPermissions[0][this.bot])) {	// this.el.nativeElement.remove();}

		 // (function() {

		//  const newscript = document.createElement('script');
		//  newscript.type = 'text/javascript';
		//  newscript.async = true;
		//  newscript.src = 'https://cdn.botframework.com/botframework-webchat/latest/webchat.js';
		//  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(newscript);

		//  newscript.onload = function() {
		// const botUrl = 'https://webchat.botframework.com/embed/claro_bot?s=14jADppO3cQ.4wFiG7gJxWfX94C7wfM62neuBeZLzoIGxwq1pc-dp6I';

		// $('#chatBot').attr('src', botUrl);

		 try {

		const styleOptions = {
		bubbleBackground: 'rgba(0, 0, 255, .1)',
		bubbleFromUserBackground: 'rgba(0, 255, 0, .1)'
		};

		window.WebChat.renderWebChat({
		directLine: window.WebChat.createDirectLine({ token: '14jADppO3cQ.4wFiG7gJxWfX94C7wfM62neuBeZLzoIGxwq1pc-dp6I' }),
		userID: '1',
		botAvatarInitials: 'BF',
		userAvatarInitials: 'WC',
		styleOptions
		}, document.getElementById('webchat'));

		window.addEventListener('webchatincomingactivity', ( data ) => {
		console.log(`Received an activity of type "${data.type}":`);
		console.log(data);
		});
		// document.querySelector('#webchat > *').focus();

		$('body .modal-dialog  .modal-body #webchat .main').css('position', 'fixed');
		$('body .modal-dialog  .modal-body #webchat .main').css('bottom', '0px');
		$('body .modal-dialog  .modal-body #webchat .main').css('width', '100%');

		} catch (e) {
		console.log('tttttt');
		}

		// };
		// })();
		}
	}

}
