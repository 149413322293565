import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { Subscription } from 'rxjs';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { ClosureInputsSelected } from 'src/app/interfaces/closure-inputs.interface';
import { Solot } from 'src/app/interfaces/project-solot.interface';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { Paginator } from '../../interfaces/paginator.interface';
import swal from 'sweetalert2';
import { SolotHeaderContainerComponent } from './solot-header-container/solot-header-container.component';
import { SolotCard5Component } from '../solot-card5/solot-card5.component';

export interface SaveSolotProjectionParams {
	data: SaveSolotProjectionData[];
	checkAll: boolean;
	except: number[];
	params: { [propName: string]: string | number };
	projectId: number;
	solotSubTypeId: string;
}

export interface SaveSolotProjectionData {
	solotId: string;
	closureProjected: string;
	closureChance: any;
	closureChangeCause: string;
}

export interface SolotNoteParams {
	solotId: string;
	lastRelevantsFacts: string;
}

@Component({
	selector: 'app-solots-card-container',
	templateUrl: './solots-card-container.component.html',
	styleUrls: ['./solots-card-container.component.scss']
})

export class SolotsCardContainerComponent implements OnInit, OnDestroy, OnChanges {
	@Input() isPMO?;
	@Input() solots: Solot[] = [];
	@Input() paginator: Paginator;
	@Input() isLoading: boolean;
	@Input() allSearchParams: { [propName: string]: string | number } = { };
	@Input() checkAll = false;
	@Input() projectId?;
	@Input() typeId?;

	@Output() checkAllChange = new EventEmitter<boolean>();
	@Output() loadMore = new EventEmitter<Paginator>();
	@Output() refreshClosureProjected = new EventEmitter<boolean>();

	@ViewChild(SolotHeaderContainerComponent, { static: true }) headerContainer: SolotHeaderContainerComponent;
	@ViewChildren(SolotCard5Component) solotCard: SolotCard5Component[];
	savingTableChanges = false;
	selected = 0;
	solotsIdsExceptions: { [propName: string]: boolean } = { };
	closureChangeCauseList: FilterConcept[];
	projectedClosingMonthList: FilterConcept[];
	closingProbability: FilterConcept[];
	pivotMonthList: FilterConcept[];
	saveSolotSubscription: Subscription;

	constructor(
		private helpers: HelpersServices,
		private api: ProjectsServices,
	) { }

	ngOnInit() {
		this.selected = 0;
		this.getProjectionOptions();
	}

	ngOnDestroy() {
		if (this.saveSolotSubscription) {
			this.saveSolotSubscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const { checkAll } = changes;
		if (checkAll && checkAll.currentValue === false) {
			this.selected = 0;
		}
	}

	onExpandAll(expanded: boolean) {
		this.solotCard.forEach(card => {
			card.showMore = expanded;
		});
	}

	onScrollDown() {
		if (this.isNextPageAvailable()) {
			this.paginator.page++;
			this.loadMore.emit(this.paginator);
		}
	}

	onScrollUp() {
		if (this.paginator && this.paginator.page > 0) {
			console.log('Scrolling up');
			// do something!
		}
	}

	private isNextPageAvailable(): boolean {
		return (this.paginator && (this.paginator.page ? this.paginator.page : 1) < this.getTotalPages());
	}

	private getTotalPages(): number {
		return (this.paginator.size > 0)
			? Math.ceil(this.paginator.total / this.paginator.size)
			: 0;
	}

	onSetAllMarkedSolots(inputs: ClosureInputsSelected) {
		const data = this.getAllAvailableSolotsByPermission()
			.filter(solot => solot.selected)
			.map(solot => {
				solot.closureProjected = (this.validatePermissions(solot, 'closure-projection') && inputs.projectedClosingMonth != null)
					? inputs.projectedClosingMonth
					: solot.closureProjected;

				solot.closureChangeCause = (this.validatePermissions(solot, 'closure-change-reason') && inputs.closureChangeCause != null)
					? inputs.closureChangeCause
					: solot.closureChangeCause;

				return {
					solotId: solot.solotId,
					closureProjected: solot.closureProjected,
					closureChance: solot.closureChance,
					closureChangeCause: solot.closureChangeCause,
				};
			});

		if (this.checkAll) {
			this.savingSolotsProjection([data[0]], true);
		} else {
			this.savingSolotsProjection(data, true);
		}
	}

	onCheckAll(checked: boolean): void {
		this.checkAll = checked;
		this.checkAllChange.emit(checked);
		this.selected = checked ? this.paginator.total : 0;
		this.getAllAvailableSolotsByPermission()
			.forEach(solot => solot.selected = checked);
	}

	getAllAvailableSolotsByPermission(): Solot[] {
		return this.solots
			.filter(s => {
				return this.validatePermissions(s, 'closure-projection') || this.validatePermissions(s, 'closure-change-reason');
			});
	}

	validatePermissions(solot: Solot, permissionString: string): boolean {
		return this.helpers.closureProjectedPermission(
			permissionString,
			solot.status,
			solot.isClosed,
			parseInt(solot.solotId, 10)
		);
	}

	onToggleSelectSolot(solot: Solot, _: number): any {
		if (this.checkAll) {
			this.solotsIdsExceptions[solot.solotId] = !solot.selected;
		}
		if (solot.selected) {
			this.selected++;
		} else {
			this.selected--;
		}
	}

	getProjectionOptions(): void {
		this.api.getClosureChangeCauseList().subscribe((response) => {
			this.closureChangeCauseList = response.data || [];
		});

		this.api.getClosureProjectionList().subscribe((response) => {
			this.projectedClosingMonthList = response.data || [];
		});

		this.api.getPivotMonthList().subscribe((response) => {
			this.pivotMonthList = response.data || [];
		});

		this.closingProbability = [
			{ id_code: '50', ds_name: '50%' },
			{ id_code: '100', ds_name: '100%' },
		];
	}

	onSolotSave(solot: Solot) {
		const data: SaveSolotProjectionData = {
			solotId: solot.solotId,
			closureProjected: solot.closureProjected,
			closureChance: solot.closureChance,
			closureChangeCause: solot.closureChangeCause,
		};

		this.savingSolotsProjection([data], false);
	}

	getExceptions(): number[] {
		const exceptions = [];

		for (const solotId in this.solotsIdsExceptions) {
			if (Object.prototype.hasOwnProperty.call(this.solotsIdsExceptions, solotId)) {
				const isSelected = this.solotsIdsExceptions[solotId];
				if (isSelected) {
					exceptions.push(solotId);
				}
			}
		}

		return exceptions;
	}

	savingSolotsProjection(data: SaveSolotProjectionData[], alert?: boolean): void {
		const params: SaveSolotProjectionParams = {
			data,
			checkAll: alert && this.checkAll,
			except: this.getExceptions(),
			params: this.allSearchParams,
			projectId: this.projectId,
			solotSubTypeId:  this.typeId
		};

		this.savingTableChanges = true;
		this.headerContainer.savingChanges = true;
		this.saveSolotSubscription = this.api.setSolotClosureProjectionV2(params).subscribe(
			response => {
				this.savingTableChanges = false;
				this.headerContainer.savingChanges = false;
				if (response.status) {
					this.refreshClosureProjected.emit(true);
					if (alert) {
						swal.fire(
							'Guardado!',
							'Registros actualizados',
							'success'
						);
					} else {
						this.helpers.notify({
							title: 'Guardado',
							message: 'Datos actualizados exitosamente!',
							type: 'success',
							options: { timeOut: 3000 },
						});
					}
				} else {
					this.helpers.handleErrors({ message: response.message });
				}
			},
			error => {
				this.headerContainer.savingChanges = false;
				this.savingTableChanges = false;
				this.helpers.handleErrors(error);
			}
		);
	}

	getSolotsNoteListParams(relevantFacts: string): SolotNoteParams[] {
		const data: SolotNoteParams[] = this.getAllAvailableSolotsByPermission()
			.filter(solot => solot.selected)
			.map(solot => {
				solot.lastRelevantsFacts = relevantFacts;
				return solot;
			})
			.map((solot): SolotNoteParams => ({
				solotId: solot.solotId,
				lastRelevantsFacts: solot.lastRelevantsFacts,
			}));

		return this.checkAll ? [data[0]] : data;
	}

	setAllRelevantFacts(relevantFacts: string) {
		const params = {
			data: this.getSolotsNoteListParams(relevantFacts),
			checkAll: this.checkAll,
			except: this.getExceptions(),
			params: this.allSearchParams,
			projectId: this.projectId
		};
		if (params.data.length) {
			this.headerContainer.savingLastRelevantsFacts = true;
			this.api.setSolotNoteV2(params).subscribe(
				(response) => {
					this.headerContainer.savingLastRelevantsFacts = false;

					if (response.status) {
						swal.fire(
							'Guardado!',
							'Registros actualizados',
							'success'
						);
					} else {
						this.helpers.notify(this.helpers.errorMessage);
					}
				},
				(error) => {
					this.headerContainer.savingLastRelevantsFacts = false;
					this.helpers.handleErrors(error);
				}
			);
		}
	}

}
