import { Component, OnInit, Input } from '@angular/core';
import { ListItem } from '../../../interfaces/list-item.interface';

@Component({
	selector: 'app-list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
	@Input() item: ListItem;
	@Input() isActive: boolean;

	constructor() { }

	ngOnInit() {
	}

}
