import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Paginator } from 'src/app/interfaces/paginator.interface';
import { ListItem, ListItemDefaultOptions } from '../../interfaces/list-item.interface';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnChanges, OnDestroy {
	@Input() list: ListItem[];
	@Input() options: ListItemDefaultOptions;
	@Input() isLoading: boolean;
	@Input() search?: string;
	@Input() selected?: any;
	@Input() paginator: Paginator;

	@Output() loadMore = new EventEmitter<Paginator>();
	@Output() searching = new EventEmitter<string>();
	@Output() select: EventEmitter<any> = new EventEmitter<any>();

	filteredRecords: ListItem[];
	searchChanged: Subject<string> = new Subject<string>();
	searchSubscription: Subscription;
	itemSelected?: ListItem;
	config: ListItemDefaultOptions;
	defaults: ListItemDefaultOptions = {
		recordsLimit: 100,
	};

	constructor() {
		this.searchSubscription = this.searchChanged
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe((text) => {
				this.search = text;

				if (this.paginator) {
					this.isLoading = true;
					this.searching.emit(this.search);
				} else {
					this.filteredRecords = this.getList();
				}

			});
	}

	ngOnDestroy(): void {
		this.searchSubscription.unsubscribe();
	}

	ngOnInit() {
		this.filteredRecords = [];
		this.search = this.search || '';
		this.config = Object.assign({ }, this.defaults, this.options);
	}

	onSelect(item) {
		if (item) {
			this.itemSelected = item;
			this.select.emit(item);
		}
	}

	ngOnChanges(change) {
		if (change.list && change.list.currentValue) {
			this.filteredRecords = this.list.map(itemList => itemList);

			const item = this.list.find(i => i.id == this.selected) || change.list.currentValue[0];

			this.onSelect(item);
		}
	}

	onScrollDown() {
		if (this.isNextPageAvailable()) {
			this.paginator.page++;
			this.loadMore.emit(this.paginator);
		}
	}

	isNextPageAvailable(): boolean {
		return (this.paginator && (this.paginator.page ? this.paginator.page : 1) < this.getTotalPages());
	}

	getTotalPages(): number {
		return (this.paginator.size > 0)
			? Math.ceil(this.paginator.total / this.paginator.size)
			: 0;
	}

	onSearching(text: string) {
		this.searchChanged.next(text);
	}

	getList(): ListItem[] {
		if (this.list) {
			const text = new RegExp(this.search, 'gi');

			return this.list.filter(item => JSON.stringify(Object.values(item)).match(text));
		}
		return [];
	}

}
