import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputParameters, ProjectFiltersInterface, ProjectInputsFilterData, ProjectInputsList } from 'src/app/interfaces/filters-inputs.interface';
import { FilterConcept, GetProjectListParams } from 'src/app/interfaces/projects.interfaces';
import { SearchOptions } from 'src/app/interfaces/search.interface';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { HelpersServices } from '../../../helpers/helpers.services';

@Component({
	selector: 'app-secondary-filters',
	templateUrl: './secondary-filters.component.html',
	styleUrls: ['./secondary-filters.component.scss']
})
export class SecondaryFiltersComponent implements OnInit {
	@Input() searchOptions: SearchOptions;
	@Input() expanded: boolean;
	@Input() collapsed: boolean;
	@Input() params: GetProjectListParams;
	@Input() tooltip;

	@Output() expandedChange = new EventEmitter<boolean>();
	@Output() filtering = new EventEmitter<ProjectFiltersInterface>();

	inputsSelected: FilterConcept[];
	searchTexts: string[] = [];
	inputsData: ProjectInputsFilterData;
	inputsList: ProjectInputsList;
	searchOptionsDefault: SearchOptions = {
		placeholder: 'Agregar texto a la búsqueda',
		tooltip: 'Agregar a la búsqueda',
	};
	constructor(
		private api: ProjectsServices,
		private helper: HelpersServices,
	) {
		this.params = { ...this.params };
	}

	ngOnInit() {
		this.inputsData = { };
		this.inputsList = { };
		this.inputsSelected = [];

		this.setManualListsInfo();
		this.getProjectStatusList();

		let preselectedSearch: FilterConcept[] = [];
		if (this.params && Array.isArray(this.params.search)) {
			preselectedSearch = this.params.search
				.map((text, index): FilterConcept => ({
					id_code: index.toString(),
					ds_name: text
				}));
		} else if (this.params && typeof this.params.search === 'string' && this.params.search.length) {
			preselectedSearch = (this.params.search || '')
				.split(',')
				.map((text, index): FilterConcept => ({
					id_code: index.toString(),
					ds_name: text
				}));
		}

		if (preselectedSearch) {
			this.onRemoveFilter(preselectedSearch);
		}
	}

	setManualListsInfo(): void {
		this.inputsList.projectClassificationTypes = [
			{ id_code: '1', ds_name: 'Complejo' },
			{ id_code: '2', ds_name: 'Simple' },
		];
		this.inputsList.projectSanctionsList = [
			{ id_code: null, ds_name: 'Sin información' },
			{ id_code: '1', ds_name: 'Si' },
			{ id_code: '0', ds_name: 'No' },
		];
		this.inputsList.projectOnTimeStatusList = [
			{ id_code: '1', ds_name: 'Dentro de plazo' },
			{ id_code: '0', ds_name: 'Fuera de plazo' },
		];
		this.inputsData.projectSanctionsSelected = this.helper.getItemSelected(
			this.inputsList.projectSanctionsList,
			this.params.isSanctioned
		);
		this.inputsData.projectClassificationTypesSelected = this.helper.getItemSelected(
			this.inputsList.projectClassificationTypes,
			this.params.managementType,
		);
		this.inputsData.projectOnTimeStatusSelected = this.helper.getItemSelected(
			this.inputsList.projectOnTimeStatusList,
			this.params.onTimeStatus,
		);
		this.searchOptions = {
			...this.searchOptionsDefault,
			...this.searchOptions,
		};
	}

	notifyInputChange(inputName: InputParameters) {
		this.filtering.emit({
			inputsFilterData: this.inputsData,
			inputsFilterParams: this.params,
			inputsFilterList: this.inputsList,
			inputChanged: inputName,
			searchTexts: this.searchTexts,
		});
	}

	getProjectStatusList(): void {
		this.inputsList.projectStatusList = [];
		this.api.getStatuses().subscribe(
			(response) => {
				if (response.status) {
					this.inputsList.projectStatusList = response.data;

					if (!this.params.statusId) {
						this.inputsData.projectStatusSelected = this.helper.getItemSelected(
							this.inputsList.projectStatusList,
							this.inputsList.projectStatusList
								.filter(status => status.checked)
								.map(status => status.id_code)
								.join(','),
						);
						this.onStatusChange();
					} else {
						this.inputsData.projectStatusSelected = this.helper.getItemSelected(
							this.inputsList.projectStatusList,
							this.params.statusId,
						);
						this.notifyInputChange(InputParameters.PROJECT_STATUS);
					}
				}
			},
			(error) => this.helper.handleErrors(error)
		);
	}

	onStatusChange(): void {
		this.params.statusId = this.helper.prepareArrayToSendParameter(
			this.inputsData.projectStatusSelected
		);
		this.notifyInputChange(InputParameters.PROJECT_STATUS);
	}

	onSearching(text: string): void {
		this.searchTexts.push(text);
		this.params.search = this.searchTexts;
		this.inputsSelected = this.searchTexts.map((txt, index) => ({
			id_code: index.toString(),
			ds_name: txt,
		}));
		this.notifyInputChange(InputParameters.SEARCH);
	}

	toggleExpand(): void {
		this.expanded = !this.expanded;
		this.expandedChange.emit(this.expanded);
	}

	onRemoveFilter(remaining: FilterConcept[]) {
		this.searchTexts = remaining.map(item => item.ds_name);
		this.params.search = this.searchTexts;
		this.inputsSelected = this.searchTexts.map((txt, index) => ({
			id_code: index.toString(),
			ds_name: txt,
		}));
		this.notifyInputChange(InputParameters.SEARCH);
	}

}
