import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KpiGroup, KpiGroupResponse } from 'src/app/interfaces/projects.interfaces';

@Component({
	selector: 'app-kpi-container',
	templateUrl: './kpi-container.component.html',
	styleUrls: ['./kpi-container.component.scss']
})
export class KpiContainerComponent implements OnInit {
	@Input() kpiGroupList: KpiGroup[];
	@Input() isLoading: boolean;
	@Output() select = new EventEmitter<KpiGroupResponse>();

	constructor() { }

	ngOnInit() {
	}

	onSelect(selection: KpiGroupResponse) {
		for (const group of this.kpiGroupList) {
			for (const item of group.values) {
				if (item.checked && (item.kpiId != selection.kpiItemId || group.kpiId != selection.kpiGroupId)) {
					item.checked = false;
				}
			}
		}
		this.select.emit(selection);
	}

}
