import { FilterConcept, GetProjectListParams } from 'src/app/interfaces/projects.interfaces';
import { DateRangePickerSelected } from 'src/app/components/solots-filter/entity-solots-filters/entity-solots-filters.component';

export interface InputsFilterData {
	buildingsSelected?: FilterConcept[];
	managersSelected?: FilterConcept[];
	engineersSelected?: FilterConcept[];
	responsibleSelected?: FilterConcept[];
	statusesSelected?: FilterConcept[];
	businessExecutivesSelected?: FilterConcept[];
	salesExecutivesSelected?: FilterConcept[];
	edacSelected?: FilterConcept[];
	solotSubTypesSelected?: FilterConcept[];
	solotsOnTimeSelected?: FilterConcept[];
	solotPfvStatusSelected?: FilterConcept[];
	closureProjectionSelected?: FilterConcept[];
	dateRangeSelected?: DateRangePickerSelected;
	solotValiditySelected?: FilterConcept[];
	areaSelected?: FilterConcept[];
	classificationSelected?: FilterConcept[];
	serviceTypeSelected?: FilterConcept[];
	solotTypeSelected?: FilterConcept[];
	solotDataSAPSelected?: FilterConcept[];
}

export enum InputParameters {
	BUILDINGS = 'BUILDINGS',
	MANAGERS = 'MANAGERS',
	RESPONSIBLE = 'RESPONSIBLE',
	PROJECT_STATUS = 'PROJECT_STATUS',
	CLOSURE_PROJECTION = 'CLOSURE_PROJECTION',
	ENGINEERS = 'ENGINEERS',
	TECH_LEADERS = 'TECH_LEADERS',
	TI_LEADERS = 'TI_LEADERS',
	BUSINESS_EXECUTIVE = 'BUSINESS_EXECUTIVE',
	SALES_EXECUTIVE = 'SALES_EXECUTIVE',
	EDAC = 'EDAC',
	PROJECT_CLASSIFICATION_TYPE = 'PROJECT_CLASSIFICATION_TYPE',
	SOLOT_SUBTYPE = 'SOLOT_SUBTYPE',
	SOLOT_ON_TIME_STATUS = 'SOLOT_ON_TIME_STATUS',
	PFV_STATUS = 'PFV_STATUS',
	ON_TIME_STATUS = 'ON_TIME_STATUS',
	SANCTIONS = 'SANCTIONS',
	SEARCH = 'SEARCH',
	SOLOT_LAST_UPDATE = 'SOLOT_LAST_UPDATE',
	DATE_RANGE = 'DATE_RANGE',
	DATE_RANGE_ONTIME = 'DATE_RANGE_ONTIME',
	DATE_RANGE_PFV = 'DATE_RANGE_PFV',
	DATE_RANGE_STATUS = 'DATE_RANGE_STATUS',
	DATE_RANGE_CLOSING = 'DATE_RANGE_CLOSING',
	SOLOT_VALID = 'SOLOT_VALID',
	AREAS = 'AREAS',
	TYPE = 'TYPE',
	SERVICE_TYPE = 'SERVICE_TYPE',
	SOLOT_TYPE = 'SOLOT_TYPE',
	COLOCATION = 'COLOCATION',
	IMPUTATION = 'IMPUTATION'
}

export interface ProjectInputsFilterData {
	buildingsSelected?: FilterConcept[];
	managersSelected?: FilterConcept[];
	engineersSelected?: FilterConcept[];
	techLeadersSelected?: FilterConcept[];
	tiLeadersSelected?: FilterConcept[];
	businessExecutivesSelected?: FilterConcept[];
	salesExecutivesSelected?: FilterConcept[];
	edacSelected?: FilterConcept[];
	projectClassificationTypesSelected?: FilterConcept[];
	projectOnTimeStatusSelected?: FilterConcept[];
	projectStatusSelected?: FilterConcept[];
	projectSanctionsSelected?: FilterConcept[];
	dateRangeSelected?: FilterConcept[];
	closureProjectionSelected?: FilterConcept[];
	projectTypeSelected?: FilterConcept[];
	colocationSelected?: FilterConcept[];
}

export interface ProjectInputsList {
	buildingsList?: FilterConcept[];
	managersList?: FilterConcept[];
	engineersList?: FilterConcept[];
	techLeadersList?: FilterConcept[];
	tiLeadersList?: FilterConcept[];
	businessExecutivesList?: FilterConcept[];
	salesExecutivesList?: FilterConcept[];
	edacList?: FilterConcept[];
	projectClassificationTypes?: FilterConcept[];
	projectOnTimeStatusList?: FilterConcept[];
	projectStatusList?: FilterConcept[];
	projectSanctionsList?: FilterConcept[];
	suppliesManagerList?: FilterConcept[];
	userList?: FilterConcept[];
	closureProjectionList?: FilterConcept[];
	projectTypeList?: FilterConcept[];
	colocationList?: FilterConcept[];
}

export interface SolotInputsList {
	buildingsList?: FilterConcept[];
	managersList?: FilterConcept[];
	engineersList?: FilterConcept[];
	responsibleList?: FilterConcept[];
	statusesList?: FilterConcept[];
	edacList?: FilterConcept[];
	businessExecutivesList?: FilterConcept[];
	salesExecutivesList?: FilterConcept[];
	closureProjectionList?: FilterConcept[];
	solotTypeList?: FilterConcept[];
	pfvStatusList?: FilterConcept[];
	onTimeStatusList?: FilterConcept[];
	solotValidityList?: FilterConcept[];
	solotDataSAPList?: FilterConcept[];
}

export interface InputsFilterParams {
	solotImputationId?: string;
	page?: number;
	size?: number;
	count?: number;
	txt?: string;
	sorts?: any[];
	buildingId?: string;
	managerId?: string;
	engineerId?: string;
	businessExecutiveId?: string;
	salesExecutiveId?: string;
	edacId?: string;
	responsibleId?: string;
	statusId?: string[] | string;
	solotStatusId?: string;
	solotSubTypeId?: string;
	solotOnTimeStatusId?: string;
	solotPfvStatusId?: string;
	closureProjectionId?: string;
	startDate?: string;
	endDate?: string;
	startDateClosing?: string;
	endDateClosing?: string;
	kpiParentId?: number;
	kpiId?: number;
	search?: string[] | string;
	fieldSelected?: string[] | string;
	startDatePfv?: string;
	endDatePfv?: string;
	startDateStatus?: string;
	endDateStatus?: string;
	startDateOntime?: string;
	endDateOntime?: string;
	pivotNotStatusId?: number;
	solotValidId?: string[] | string;
	projectId?: string;
	areaId?: string[] | string;
	classificationId?: string[] | string;
	serviceTypeId?: string[] | string;
	solotTypeId?: string[] | string;
	fileName?: string;
	navigationId?: string;
}

export interface InputsMinuteParams {
	deliveryDocumentId?: number;
	typeDelivery?: number;
	minuteIssueDate?: string;
	manager?: string;
	managerId?: string;
	managerCin?: string;
	managerPosition?: string;
	customer?: string;
	customerCin?: string;
	represented?: string;
	representedCin?: string;
	representedPosition?: string;
	minuteDescription?: string;
	minuteCriteria?: string;
	solots?: string[] | string;
}

export interface EntitySolotFilter {
	inputsFilterData: InputsFilterData;
	inputsFilterParams: InputsFilterParams;
	inputChanged: string;
}

export interface SolotFiltersInterface {
	inputsFilterData: InputsFilterData;
	inputsFilterParams: InputsFilterParams;
	inputChanged: string;
	searchTexts: string[];
}

export interface ProjectFiltersInterface {
	inputsFilterData: ProjectInputsFilterData;
	inputsFilterList?: ProjectInputsList;
	inputsFilterParams: GetProjectListParams;
	inputChanged: InputParameters;
	searchTexts?: string[];
}

export interface CommonInputChangeParams {
	paramIdName: 'buildingId' | 'managerId' | 'engineerId' | 'statusId' | 'techLeaderId' | 'businessExecutiveId' | 'salesExecutiveId' | 'edacId' | 'dateRange' | 'closureProjection' | 'tiLeaderId' | 'colocation';
	dataSelectedName: 'buildingsSelected' | 'managersSelected' | 'engineersSelected' | 'techLeadersSelected' | 'businessExecutivesSelected' | 'salesExecutivesSelected' | 'edacSelected' | 'dateRangeSelected' | 'closureProjectionSelected' | 'tiLeadersSelected' | 'colocationSelected';
	inputName: InputParameters;
}
