import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { UserContactInterface } from 'src/app/interfaces/users.interface';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';

@Component({
	selector: 'app-personal-info',
	templateUrl: './personal-info.component.html',
	styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {
	@Input() userId: string;

	userContact: UserContactInterface;
	isLoading: boolean;
	inError: boolean;

	constructor(
		private api: ProjectsServices,
		private helper: HelpersServices,
		private modalService: NgbModal,
		config: NgbModalConfig,
	) {
		config.backdrop = 'static';
	}

	ngOnInit() {
		this.isLoading = false;
		this.inError = false;
	}

	openUserInformation(content) {
		this.getUserContactInfo();
		this.modalService.open(content, {
			size: 'md',
			centered: true,
		});
	}

	getUserContactInfo() {
		if (this.userId) {
			this.isLoading = true;
			this.inError = false;
			this.api.getUserContactInfo({ userId: this.userId }).subscribe(
				(response) => {
					if (response.status) {
						this.userContact = response.data;
						this.userContact.picture = !this.userContact.picture
							? this.helper.getGravatarImage(this.userContact.fullName)
							: this.userContact.picture;
					} else {
						this.inError = true;
						this.helper.handleErrors({ message: response.message });
					}
					this.isLoading = false;
				},
				(error) => {
					this.inError = true;
					this.isLoading = false;
					this.helper.handleErrors(error);
				}
			);
		}
	}

}
