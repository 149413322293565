import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-skeleton',
	templateUrl: './skeleton.component.html',
	styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
	@Input() cards?: number;
	@Input() useHeader = false;
	@Input() darkMode = false;
	@Input() cardClass: string;
	@Input() bodyClass: string;

	public skeletons = [];

	constructor() { }

	ngOnInit() {
		this.cards = this.cards || 3;
		this.skeletons = Array(this.cards);
	}

}
