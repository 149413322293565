import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { Solot } from '../../interfaces/project-solot.interface';

@Component({
	selector: 'app-solot-card4',
	templateUrl: './solot-card4.component.html',
	styleUrls: ['./solot-card4.component.scss']
})

export class SolotCard4Component implements OnInit {
	@Input() solot: Solot;
	@Input() projectedClosingMonthList: FilterConcept[] = [];
	@Input() closureChangeCauseList: FilterConcept[] = [];
	@Input() saving = false;
	@Output() select: EventEmitter<Solot> = new EventEmitter<Solot>();
	@Output() save: EventEmitter<Solot> = new EventEmitter<Solot>();
	showMore = false;

	constructor(
		private helpers: HelpersServices
	) { }

	ngOnInit() {
	}

	onSelect() {
		this.select.emit(this.solot);
	}

	onSaveChanges() {
		this.save.emit(this.solot);
	}

}
