import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ComponentsServices } from '../components.services';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	test: Date = new Date();
	lastUpdate: string;

	constructor(private api: ComponentsServices) { }

	ngOnInit() {

		this.api.getLastUpdate().subscribe(response => {
			if (response.status) {
				const date = response.data[0];
				this.lastUpdate = date.lastUpdate ? moment(date.lastUpdate.substr(0, 19)).format('DD-MM-YYYY HH:mm:ss') : '' ;
			}
		});
	}
}
