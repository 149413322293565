import { NgModule } from '@angular/core';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HasDisableDirective } from '../directives/has-disable.directive';
import { BotDirective } from '../directives/bot.directive';
import { OmaInfiniteScrollDirective } from '../directives/oma-infinite-scroll.directive';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		HasPermissionDirective,
		HasDisableDirective,
		BotDirective,
		OmaInfiniteScrollDirective,
	],
	imports: [
		HttpClientModule,
		NgbModule,
		TagInputModule,
		ProgressbarModule.forRoot(),
		BsDatepickerModule.forRoot(),
		NgxDaterangepickerMd.forRoot({
			format: 'YYYY-MM-DD',
			displayFormat: 'DD/MM/YYYY',
			direction: 'ltr',
			weekLabel: 'S',
			separator: ' - ',
			cancelLabel: 'Cancelar',
			applyLabel: 'Aceptar',
			clearLabel: 'Limpiar',
			customRangeLabel: 'Personalizado',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	exports: [
		FormsModule,
		HasPermissionDirective,
		TranslateModule,
		NgSelectModule,
		NgbModule,
		TagInputModule,
		NgxChartsModule,
		SlickCarouselModule,
		NgxDatatableModule,
		ProgressbarModule,
		BsDatepickerModule,
		HasDisableDirective,
		BotDirective,
		OmaInfiniteScrollDirective,
		NgxDaterangepickerMd,
	]
})
export class SharedModule { }
