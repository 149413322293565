import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import {
	EntitySolotFilter,
	InputParameters,
	InputsFilterData,
	SolotInputsList,
	InputsFilterParams
} from 'src/app/interfaces/filters-inputs.interface';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';

moment.locale('es');

export interface DateRangePickerSelected {
	startDate: moment.Moment;
	endDate: moment.Moment;
}
@Component({
	selector: 'app-entity-solots-filters-two',
	templateUrl: './entity-solots-filters-two.component.html',
	styleUrls: ['./entity-solots-filters-two.component.scss']
})
export class EntitySolotsFiltersTwoComponent implements OnInit, OnDestroy {
	@Output() filtering = new EventEmitter<EntitySolotFilter>();

	isOpen = false;
	inputsData: InputsFilterData;
	inputsList: SolotInputsList;
	params: InputsFilterParams;
	ranges: { [name: string]: moment.Moment[] } = {
		Hoy: [moment(), moment()],
		Ayer: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Menos de 7 días': [moment().subtract(6, 'days'), moment()],
		'Entre 7 y 14 días': [moment().subtract(13, 'days'), moment().subtract(6, 'days')],
		'Entre 15 y 30 días': [moment().subtract(29, 'days'), moment().subtract(14, 'days')],
	};

	locale = {
		firstDay: 1,
		daysOfWeek: moment.weekdaysMin(),
    	monthNames: moment.monthsShort(),
	}

	buildingSubscription: Subscription;
	managerSubscription: Subscription;
	engineerSubscription: Subscription;
	businessSubscription: Subscription;
	salesSubscription: Subscription;
	edacSubscription: Subscription;
	responsibleSubscription: Subscription;
	statusesSubscription: Subscription;
	subTypesSubscription: Subscription;
	projectionSubscription: Subscription;
	pfvStatusSubscription: Subscription;
	onTimeStatusSubscription: Subscription;

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
	) { }

	ngOnInit() {
		this.inputsList = { };
		this.inputsList.solotValidityList = [
			{ id_code: '1', ds_name: 'Solots válidas' },
			{ id_code: '2', ds_name: 'Solots No válidas' },
		],
		this.inputsData = { };
		// this.inputsData.solotValiditySelected = [
		// 	{ id_code: '1', ds_name: 'Solots válidas'}
		// ]
		this.params = { };
		this.getBuildingsList();
		this.getManagersList(true);
		this.getEngineersList(true);
		this.getResponsibleList(true);
		this.getBusinessExecutiveList(true);
		this.getSalesExecutiveList(true);
		this.getEdacList(true);
		this.getSolotSubTypesList(true);
		this.getSolotPfvStatusList(true);
		this.getMonthClosureProjectionList(true);
		this.getSolotOnTimeStatusList(true);
	}

	ngOnDestroy() {
		this.buildingSubscription.unsubscribe();
		this.managerSubscription.unsubscribe();
		this.engineerSubscription.unsubscribe();
		this.responsibleSubscription.unsubscribe();
		this.businessSubscription.unsubscribe();
		this.salesSubscription.unsubscribe();
		this.edacSubscription.unsubscribe();
		this.subTypesSubscription.unsubscribe();
		this.projectionSubscription.unsubscribe();
		this.pfvStatusSubscription.unsubscribe();
		this.onTimeStatusSubscription.unsubscribe();
	}

	notifyInputChange(inputName: InputParameters) {
		this.filtering.emit({
			inputsFilterData: this.inputsData,
			inputsFilterParams: this.params,
			inputChanged: inputName
		});
	}

	getBuildingsList(): void {
		this.inputsList.buildingsList = [];
		this.buildingSubscription = this.api.getBuildings().subscribe(response => {
			if (response.status) {
				this.inputsList.buildingsList = response.data || [];
				this.inputsData.buildingsSelected = this.getItemSelected(this.inputsList.buildingsList, this.params.buildingId || '');

				if (this.inputsData.buildingsSelected && this.inputsData.buildingsSelected.length) {
					this.onBuildingChange();
				}
			}
		});
	}

	getManagersList(fromOnInit?: boolean): void {
		this.inputsList.managersList = [];
		this.managerSubscription = this.api.getSolotManagersList(this.params).subscribe(response => {
			if (response.status) {
				this.inputsList.managersList = response.data || [];

				if (fromOnInit) {
					this.inputsData.managersSelected = this.getItemSelected(this.inputsList.managersList, this.params.managerId || '');

					if (this.inputsData.managersSelected && this.inputsData.managersSelected.length) {
						this.onManagersChange();
					}
				}
			}
		});
	}

	getEngineersList(fromOnInit?: boolean): void {
		this.inputsList.engineersList = [];
		this.engineerSubscription = this.api.getSolotEngineersList(this.params).subscribe(response => {
			if (response.status) {
				this.inputsList.engineersList = response.data || [];

				if (fromOnInit) {
					this.inputsData.engineersSelected = this.getItemSelected(this.inputsList.engineersList, this.params.engineerId || '');

					if (this.inputsData.engineersSelected && this.inputsData.engineersSelected.length) {
						this.onEngineersChange();
					}
				}
			}
		});
	}

	getResponsibleList(fromOnInit?: boolean): void {
		this.inputsList.responsibleList = [];
		this.responsibleSubscription = this.api.getSolotsResponsibleList(this.params).subscribe(response => {
			if (response.status) {
				this.inputsList.responsibleList = response.data || [];

				if (fromOnInit) {
					this.inputsData.responsibleSelected = this.getItemSelected(
						this.inputsList.responsibleList,
						this.params.responsibleId || ''
					);

					if (this.inputsData.responsibleSelected && this.inputsData.responsibleSelected.length) {
						this.onResponsibleChange();
					}
				}
			}
		});
	}

	getBusinessExecutiveList(fromOnInit?: boolean): void {
		this.businessSubscription = this.api.getSolotsBusinessExecutiveList(this.params).subscribe(response => {
			if (response.status) {
				this.inputsList.businessExecutivesList = response.data || [];

				if (fromOnInit) {
					this.inputsData.businessExecutivesSelected = this.getItemSelected(
						this.inputsList.businessExecutivesList,
						this.params.businessExecutiveId || ''
					);
				}

				if (!this.params.businessExecutiveId && fromOnInit) {
					this.inputsData.businessExecutivesSelected = this.inputsList.businessExecutivesList.filter(status => status.checked);

					if (this.inputsData.businessExecutivesSelected && this.inputsData.businessExecutivesSelected.length) {
						this.onBusinessExecutiveChange();
					}
				}
			}
		});
	}

	getSalesExecutiveList(fromOnInit?: boolean): void {
		this.salesSubscription = this.api.getSolotsSalesExecutiveList(this.params).subscribe(response => {
			if (response.status) {
				this.inputsList.salesExecutivesList = response.data || [];

				if (fromOnInit) {
					this.inputsData.salesExecutivesSelected = this.getItemSelected(
						this.inputsList.salesExecutivesList,
						this.params.salesExecutiveId || ''
					);
				}

				if (!this.params.salesExecutiveId && fromOnInit) {
					this.inputsData.salesExecutivesSelected = this.inputsList.salesExecutivesList.filter(status => status.checked);

					if (this.inputsData.salesExecutivesSelected && this.inputsData.salesExecutivesSelected.length) {
						this.onSalesExecutiveChange();
					}
				}
			}
		});
	}

	getEdacList(fromOnInit?: boolean): void {
		this.edacSubscription = this.api.getSolotsEdacList(this.params).subscribe(response => {
			if (response.status) {
				this.inputsList.edacList = response.data || [];

				if (fromOnInit) {
					this.inputsData.edacSelected = this.getItemSelected(this.inputsList.edacList, this.params.edacId || '');
				}

				if (!this.params.edacId && fromOnInit) {
					this.inputsData.edacSelected = this.inputsList.edacList.filter(status => status.checked);

					if (this.inputsData.edacSelected && this.inputsData.edacSelected.length) {
						this.onEdacChange();
					}
				}
			}
		});
	}

	getSolotSubTypesList(fromOnInit?: boolean) {
		this.subTypesSubscription = this.api.getTypesList({ conceptId: '2' })
			.subscribe(response => {
				if (response.status) {
					this.inputsList.solotTypeList = response.data || [];

					if (fromOnInit) {
						this.inputsData.solotSubTypesSelected = this.helpers.getItemSelected(
							this.inputsList.solotTypeList,
							this.params.solotSubTypeId,
						);
					}

					if (!this.params.solotSubTypeId && fromOnInit) {
						this.inputsData.solotSubTypesSelected = this.inputsList.solotTypeList
							.filter(type => type.checked);

						if (this.inputsData.solotSubTypesSelected && this.inputsData.solotSubTypesSelected.length) {
							this.onSubTypeChange();
						}
					}
				}
			});
	}

	getSolotPfvStatusList(fromOnInit?: boolean) {
		this.pfvStatusSubscription = this.api.getSolotsPfvStatusList(this.params)
			.subscribe(response => {
				if (response.status) {
					this.inputsList.pfvStatusList = response.data || [];

					if (fromOnInit) {
						this.inputsData.solotPfvStatusSelected = this.helpers.getItemSelected(
							this.inputsList.pfvStatusList,
							this.params.solotPfvStatusId,
						);
					}

					if (!this.params.solotPfvStatusId && fromOnInit) {
						this.inputsData.solotPfvStatusSelected = this.inputsList.pfvStatusList
							.filter(pfv => pfv.checked);

						if (this.inputsData.solotPfvStatusSelected && this.inputsData.solotPfvStatusSelected.length) {
							this.onPfvStatusChange();
						}
					}
				}
			});
	}

	getSolotOnTimeStatusList(fromOnInit?: boolean) {
		this.onTimeStatusSubscription = this.api.getSolotsOnTimeStatusList(this.params)
			.subscribe(response => {
				if (response.status) {
					this.inputsList.onTimeStatusList = response.data || [];

					if (fromOnInit) {
						this.inputsData.solotsOnTimeSelected = this.helpers.getItemSelected(
							this.inputsList.onTimeStatusList,
							this.params.solotOnTimeStatusId,
						);
					}

					if (!this.params.solotOnTimeStatusId && fromOnInit) {
						this.inputsData.solotsOnTimeSelected = this.inputsList.onTimeStatusList
							.filter(pfv => pfv.checked);

						if (this.inputsData.solotsOnTimeSelected && this.inputsData.solotsOnTimeSelected.length) {
							this.onTimeStatusChange();
						}
					}
				}
			});
	}

	getMonthClosureProjectionList(fromOnInit?: boolean) {
		this.projectionSubscription = this.api.getMonthClosureProjectionList().subscribe(response => {
			if (response.status) {
				this.inputsList.closureProjectionList = response.data || [];

				if (fromOnInit) {
					this.inputsData.closureProjectionSelected = this.helpers.getItemSelected(
						this.inputsList.closureProjectionList,
						this.params.closureProjectionId,
						'ds_name'
					);
				}

				if (!this.params.closureProjectionId && fromOnInit) {
					this.inputsData.closureProjectionSelected = this.inputsList.closureProjectionList
						.filter(closure => closure.checked);

					if (this.inputsData.closureProjectionSelected && this.inputsData.closureProjectionSelected.length) {
						this.onClosureProjectionChange();
					}
				}
			}
		});
	}

	onBuildingChange(): void {
		this.params.buildingId = this.helpers.prepareArrayToSendParameter(this.inputsData.buildingsSelected);
		this.notifyInputChange(InputParameters.BUILDINGS);
		this.getManagersList();
		this.getEngineersList();
		this.getResponsibleList();
		this.getBusinessExecutiveList();
		this.getSalesExecutiveList();
		this.getEdacList();
	}

	onManagersChange(): void {
		this.params.managerId = this.helpers.prepareArrayToSendParameter(this.inputsData.managersSelected);
		this.notifyInputChange(InputParameters.MANAGERS);
		this.getEngineersList();
		this.getResponsibleList();
		this.getBusinessExecutiveList();
		this.getSalesExecutiveList();
		this.getEdacList();
	}

	onEngineersChange(): void {
		this.params.engineerId = this.helpers.prepareArrayToSendParameter(this.inputsData.engineersSelected);
		this.notifyInputChange(InputParameters.ENGINEERS);
	}

	onResponsibleChange(): void {
		this.params.responsibleId = this.helpers.prepareArrayToSendParameter(this.inputsData.responsibleSelected);
		this.notifyInputChange(InputParameters.RESPONSIBLE);
	}

	onBusinessExecutiveChange(): void {
		this.params.businessExecutiveId = this.helpers.prepareArrayToSendParameter(this.inputsData.businessExecutivesSelected);
		this.notifyInputChange(InputParameters.BUSINESS_EXECUTIVE);
	}

	onSalesExecutiveChange(): void {
		this.params.salesExecutiveId = this.helpers.prepareArrayToSendParameter(this.inputsData.salesExecutivesSelected);
		this.notifyInputChange(InputParameters.SALES_EXECUTIVE);
	}

	onEdacChange(): void {
		this.params.edacId = this.helpers.prepareArrayToSendParameter(this.inputsData.edacSelected);
		this.notifyInputChange(InputParameters.EDAC);
	}

	onSubTypeChange(): void {
		this.params.solotSubTypeId = this.helpers.prepareArrayToSendParameter(this.inputsData.solotSubTypesSelected, 'id_code');
		this.notifyInputChange(InputParameters.SOLOT_SUBTYPE);
	}

	onPfvStatusChange(): void {
		this.params.solotPfvStatusId = this.helpers.prepareArrayToSendParameter(this.inputsData.solotPfvStatusSelected, 'ds_name');
		this.notifyInputChange(InputParameters.PFV_STATUS);
	}

	onTimeStatusChange(): void {
		this.params.solotOnTimeStatusId = this.helpers.prepareArrayToSendParameter(this.inputsData.solotsOnTimeSelected, 'ds_name');
		this.notifyInputChange(InputParameters.SOLOT_ON_TIME_STATUS);
	}

	onClosureProjectionChange(): void {
		this.params.closureProjectionId = this.helpers.prepareArrayToSendParameter(this.inputsData.closureProjectionSelected, 'ds_name');
		this.notifyInputChange(InputParameters.CLOSURE_PROJECTION);
	}

	onChooseDate({ startDate, endDate }: DateRangePickerSelected) {
		this.params.startDate = startDate && endDate ? startDate.format('YYYY-MM-DD') : null;
		this.params.endDate = startDate && endDate ? endDate.format('YYYY-MM-DD') : null;
		this.notifyInputChange(InputParameters.SOLOT_LAST_UPDATE);
	}

	getItemSelected(data, id = '', attr = 'id_code') {
		const selectedInParams = id.replace(/\'/g, '').split(',');

		return data.filter(item => selectedInParams.some(idSelected => idSelected == item[attr]));
	}

	onValidityChange() {
		this.params.solotValidId = this.helpers.prepareArrayToSendParameter(this.inputsData.solotValiditySelected, 'id_code');
		this.notifyInputChange(InputParameters.SOLOT_VALID);
	}

}
