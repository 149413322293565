import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'projects/list',
		pathMatch: 'full'
	},
	{
		path: '',
		component: AdminLayoutComponent,
		children: [
			{
				path: 'dashboards',
				loadChildren: './pages/dashboards/dashboards.module#DashboardsModule'
			},
			{
				path: 'adminpanel',
				loadChildren: './pages/adminpanel/adminpanel.module#AdminpanelModule'
			},
			{
				path: '',
				loadChildren: './pages/projects/projects.module#ProjectsModule'
			},
		]
	},
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./layouts/auth-layout/auth-layout.module#AuthLayoutModule'
			}
		]
	},
	{
		path: '**',
		redirectTo: 'projects/list'
	}
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
			useHash: true
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
