import { InputParameters } from 'src/app/interfaces/filters-inputs.interface';
import { UserConceptParams } from 'src/app/interfaces/projects.interfaces';

export const UsersConcepts: UserConceptParams[] = [
	{
		name: InputParameters.ENGINEERS,
		conceptName: 'engineers',
		conceptIdName: 'engineerId',
		conceptListName: 'engineersList',
		conceptSelectedName: 'engineersSelected',
		fromOnInit: null,
	},
	// {
	// 	name: InputParameters.TECH_LEADERS,
	// 	conceptName: 'techLeaders',
	// 	conceptIdName: 'techLeaderId',
	// 	conceptListName: 'techLeadersList',
	// 	conceptSelectedName: 'techLeadersSelected',
	// 	fromOnInit: null,
	// },
	// {
	// 	name: InputParameters.BUSINESS_EXECUTIVE,
	// 	conceptName: 'businessExecutives',
	// 	conceptIdName: 'businessExecutiveId',
	// 	conceptListName: 'businessExecutivesList',
	// 	conceptSelectedName: 'businessExecutivesSelected',
	// 	fromOnInit: null,
	// },
	{
		name: InputParameters.SALES_EXECUTIVE,
		conceptName: 'salesExecutives',
		conceptIdName: 'salesExecutiveId',
		conceptListName: 'salesExecutivesList',
		conceptSelectedName: 'salesExecutivesSelected',
		fromOnInit: null,
	},
	{
		name: InputParameters.EDAC,
		conceptName: 'edac',
		conceptIdName: 'edacId',
		conceptListName: 'edacList',
		conceptSelectedName: 'edacSelected',
		fromOnInit: null,
	},
];
