import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';

@Component({
	selector: 'app-quick-solots-filters-two',
	templateUrl: './quick-solots-filters-two.component.html',
	styleUrls: ['./quick-solots-filters-two.component.scss']
})
export class QuickSolotsFiltersTwoComponent implements OnInit, OnChanges {
	@Input() statusList: FilterConcept[] = [];
	@Input() size: string;
	@Output() select = new EventEmitter<FilterConcept[]>();

	constructor() { }

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.statusList && changes.statusList.currentValue && changes.statusList.currentValue.some(s => s.checked)) {
			this.onStatusChange();
		}
	}

	onStatusChange(status?: FilterConcept) {
		if (status) {
			status.checked = !status.checked;
		}

		this.select.emit(this.statusList);
	}

}
