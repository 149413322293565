import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-auth-layout',
	templateUrl: './auth-layout.component.html',
	styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
	public isCollapsed = true;

	constructor( private router: Router ) { }

	ngOnInit() {
		const body = document.getElementsByTagName('body')[0];

		body.classList.add('bg-default');
	}
	ngOnDestroy() {
		const body = document.getElementsByTagName('body')[0];

		body.classList.remove('bg-default');
	}
}
