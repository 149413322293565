import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HelpersServices } from './helpers/helpers.services';
import Swal from 'sweetalert2';
import { SocketioService, PermissionUpdate } from './helpers/socketio.service';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

	constructor(
		private router: Router,
		private translate: TranslateService,
		private Helpers: HelpersServices,
		private socket: SocketioService,
	) {
		this.socket.connect();
		this.translate.setDefaultLang('es');
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				window.scrollTo(0, 0);
			}

			if (event instanceof NavigationEnd) {
				const userAuth = this.Helpers.getDecodedUser();

				if (!userAuth || !userAuth.user || !userAuth.user.userId) {
					localStorage.removeItem('userAuth');
					this.router.navigate(['/login']);
				}
			}

			if (event instanceof NavigationError) {
				console.log(event.error);
			}
		});
	}

	ngOnInit() {
		this.socket.on('permissionsChanged', (data: PermissionUpdate) => {
			const userAuth = this.Helpers.getDecodedUser();

			if (userAuth) {
				if (data.type === 'role' || data.type === 'group') {
					this.showRefreshOption();
				} else {
					if ((Array.isArray(data.userAffected) && data.userAffected.indexOf(parseInt(userAuth.user.userId, 10)) >= 0) ||
						(!Array.isArray(data.userAffected) && data.userAffected == parseInt(userAuth.user.userId, 10))) {
						this.showRefreshOption();
					}
				}
			}
		});
	}

	showRefreshOption(): void {
		this.Helpers.updateUserInfo();
		this.Helpers.toast({
			icon: 'info',
			title: 'La configuración del sistema ha cambiado',
			position: 'bottom-start',
			confirmButtonText: 'Recargar',
			showConfirmButton: true,
			timer: 0,
		}).then(() => {
			// tslint:disable-next-line: deprecation
			location.reload(true);
		});
	}
}
