import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { ClosureInputsSelected } from 'src/app/interfaces/closure-inputs.interface';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import swal from 'sweetalert2';

@Component({
	selector: 'app-solot-header-container',
	templateUrl: './solot-header-container.component.html',
	styleUrls: ['./solot-header-container.component.scss']
})
export class SolotHeaderContainerComponent implements OnInit {
	@Input() projectedClosingMonthList: FilterConcept[] = [];
	@Input() closureChangeCauseList: FilterConcept[] = [];
	@Input() itemsSelected = 0;
	@Input() totalItems = 0;
	@Input() selectAll = false;
	@Input() expandAll = false;

	@Output() setAll = new EventEmitter<ClosureInputsSelected>();
	@Output() selectAllChange = new EventEmitter<boolean>();
	@Output() expandAllChange = new EventEmitter<boolean>();
	@Output() setAllRelevantFacts = new EventEmitter<string>();

	openingRelevantFacts = false;
	newLatestRelevantFacts: string;
	savingLastRelevantsFacts = false;
	savingChanges = false;
	expandedAll = false;
	inputSelected: ClosureInputsSelected = {
		projectedClosingMonth: null,
		closureChangeCause: null,
	};

	constructor(
		private helpers: HelpersServices,
		private modalService: NgbModal,
		config: NgbModalConfig,
	) {
		this.itemsSelected = 0;
		this.totalItems = 0;
		config.backdrop = 'static';
	}

	ngOnInit() {
	}

	onCheckAll(checked: boolean) {
		this.selectAllChange.emit(checked);
	}

	onExpandColapseAll() {
		this.expandedAll = !this.expandedAll;
		this.expandAllChange.emit(this.expandedAll);
	}

	setClosureProjection() {
		if (this.helpers.hasPermission('closure-projection')) {
			this.savingChanges = true;
			if (this.itemsSelected) {
				this.warningMessage().then(response => {
					if (response.isConfirmed) {
						this.setAll.emit(this.inputSelected);
					} else {
						this.savingChanges = false;
					}
				});
			} else {
				this.savingChanges = false;
				this.helpers.notify({
					title: 'Advertencia!',
					message: 'Para esta opción, debe seleccionar al menos un registro de la lista',
					type: 'warning',
				});
			}
		}
	}

	openViewRelevantFacts(content): void {
		if (this.itemsSelected) {
			const modalConfig = {
				size: 'lg',
				centered: true,
			};

			this.modalService.open(content, modalConfig)
				.result.then(() => {
					this.openingRelevantFacts = false;
					this.newLatestRelevantFacts = '';
				});
		} else {
			this.helpers.notify({
				title: 'Advertencia!',
				message: 'Para esta opción, debe seleccionar al menos un registro de la lista',
				type: 'warning',
			});
		}
	}

	setRelevantFacts() {
		if (this.helpers.hasPermission('closure-change-reason')) {
			this.savingLastRelevantsFacts = true;
			this.warningMessage().then(() => {
				this.setAllRelevantFacts.emit(this.newLatestRelevantFacts);
			});
		}
	}

	warningMessage() {
		return swal.fire<boolean>({
			title: 'Actualización masiva!',
			html: `Todas las solots marcadas serán configuradas según sus permisos con los datos seleccionados<br>Esta seguro(a) de actualizar las solots marcadas?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Actualizar todas!',
			cancelButtonText: 'No, Cancelar!'
		});
	}

}
