import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { EntitySolotFilter, SolotFiltersInterface } from 'src/app/interfaces/filters-inputs.interface';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';

export interface FilterIndicatorInterface {
	label: string;
	class?: string | { [name: string]: boolean };
	value: string;
}

@Component({
	selector: 'app-solots-filter',
	templateUrl: './solots-filter.component.html',
	styleUrls: ['./solots-filter.component.scss']
})
export class SolotsFilterComponent implements OnInit, OnDestroy {
	@Input() indicators: FilterIndicatorInterface[];
	@Input() isLoading: boolean;
	@Input() tooltips;
	@Input() showEntityFilter?: boolean;
	@Output() filtering = new EventEmitter<SolotFiltersInterface>();

	statusSubscription: Subscription;
	inputsSelected: FilterConcept[] = [];
	solotStatusList: FilterConcept[];
	filters: SolotFiltersInterface = {
		inputsFilterData: { },
		inputsFilterParams: { },
		inputChanged: '',
		searchTexts: [],
	};

	constructor(
		private api: ProjectsServices,
		private helpers: HelpersServices,
	) { }

	ngOnInit() {
		this.showEntityFilter = (this.showEntityFilter != undefined) ? this.showEntityFilter : true;
		this.getStatusList({ workflowId: 2 });
	}

	ngOnDestroy() {
		if (this.statusSubscription) {
			this.statusSubscription.unsubscribe();
		}
	}

	getStatusList(params: { workflowId: number }): void {
		if (this.statusSubscription) {
			this.statusSubscription.unsubscribe();
		}

		this.statusSubscription = this.api.getStatuses(params).subscribe((response) => {
			if (response.status) {
				this.solotStatusList = response.data || [];
			}
		});
	}

	showSelected() {
		this.inputsSelected = this.filters.searchTexts.map((text, index) => ({
			id_code: index.toString(),
			ds_name: text
		}));
	}

	onSearching(text: string = '') {
		this.filters.searchTexts.push(text);
		this.notifyInputChange();
	}

	onFiltering(filters: EntitySolotFilter) {
		this.filters.inputsFilterParams = {
			...this.filters.inputsFilterParams,
			...filters.inputsFilterParams,
		};
		this.filters.inputsFilterData = filters.inputsFilterData;
		this.filters.inputChanged = filters.inputChanged;
		this.notifyInputChange();
	}

	onStatusChange(statuses: FilterConcept[]) {
		this.filters.inputsFilterParams.solotStatusId = this.helpers.prepareArrayToSendParameter(statuses.filter(status => status.checked));
		this.notifyInputChange();
	}

	notifyInputChange() {
		this.showSelected();
		this.filtering.emit(this.filters);
	}

	onRemoveFilter(remaining: FilterConcept[]) {
		this.filters.searchTexts = remaining.map(item => item.ds_name);
		this.filtering.emit(this.filters);
	}

}
