/*!

=========================================================
* Argon Dashboard PRO Angular - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-angular
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Sentry.init({
// 	dsn: 'https://665e2ea2765440efb52d0d2dc36bf4c6@o1150802.ingest.sentry.io/6224593',
// 	integrations: [
// 		new BrowserTracing({
// 			tracingOrigins: ['localhost', 'https://claro-qa-api.blixter.cl/api', 'https://claro-api.blixter.cl/api'],
// 			routingInstrumentation: Sentry.routingInstrumentation,
// 		}),
// 	],

// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 1.0,
// 	});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
