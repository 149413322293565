import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { Solot } from '../../interfaces/project-solot.interface';
import { SolotDisconnectionActionControlComponent } from './solot-disconnection-action-control/solot-disconnection-action-control.component';

@Component({
	selector: 'app-solot-disconnection-card',
	templateUrl: './solot-disconnection-card.component.html',
	styleUrls: ['./solot-disconnection-card.component.scss']
})

export class SolotCardDisconnectionComponent implements OnInit, OnChanges {
	@Input() solot: Solot;
	@Input() projectedClosingMonthList: FilterConcept[] = [];
	@Input() closureChangeCauseList: FilterConcept[] = [];
	@Input() saving = false;
	@Input() buttonDisabled = false;
	@Input() checkedView ? = true;
	@Input() inputList;

	@Output() select: EventEmitter<Solot> = new EventEmitter<Solot>();
	@Output() save: EventEmitter<Solot> = new EventEmitter<Solot>();

	@ViewChild(SolotDisconnectionActionControlComponent, { static: true }) cardControls: SolotDisconnectionActionControlComponent;
	showMore = false;

	constructor(
		private helpers: HelpersServices
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (this.cardControls.solot) {
			this.cardControls.ngOnInit();
		}
	}

	ngOnInit() {
		this.solot.servicesNumbers = this.solot.servicesNumbers.slice(0, -2);
	}

	onSelect() {
		this.select.emit(this.solot);
	}

	onSaveChanges() {
		this.save.emit(this.solot);
	}

}
