import { RouteInfo } from '../../interfaces/menu.interfaces';

export const ROUTES: RouteInfo[] = [
	{
		path: '',
		title: 'Paneles',
		type: 'sub',
		icontype: 'ni-chart-pie-35 text-primary',
		collapse: 'panels',
		isCollapsed: true,
		children: [
			{ path: '/dashboards/power-bi', title: 'Indicadores', type: 'link' },
			{ path: '/dashboards/dashboard', title: 'Generales', type: 'link' },
			{ path: '/dashboards/power-bi-2', title: 'Gestores', type: 'link' },
			{ path: '/dashboards/power-bi-3', title: 'RRCC y SSTT', type: 'link' },
			{ path: '/dashboards/power-bi-4', title: 'Proyectos Complejos', type: 'link' },
		]
	},
	{
		path: '/projects',
		title: 'Implementaciones',
		type: 'sub',
		icontype: 'ni-app text-primary',
		collapse: 'implementations',
		isCollapsed: true,
		children: [
			{ path: '/projects/list', title: 'Proyectos', type: 'link'},
			{ path: '/projects/projects-solot', title: 'SOLOTs', type: 'link'},
		]
	},
	{
		path: '/projects',
		title: 'Desconexiones',
		type: 'sub',
		icontype: 'ni-app text-primary',
		collapse: 'disconnections',
		isCollapsed: true,
		children: [
			{ path: '/projects/disconnection-solots', title: 'SOLOTs', type: 'link' },
		]
	},
];

export const CONFIG: RouteInfo[] = [
	{
		path: '/adminpanel',
		title: 'Administración',
		type: 'sub',
		icontype: 'ni-settings-gear-65 text-primary',
		collapse: 'adminpanel',
		isCollapsed: true,
		children: [
			{ path: '/adminpanel/users', title: 'Usuarios', type: 'link' },
			{ path: '/adminpanel/roles', title: 'Roles', type: 'link' },
			{ path: '/adminpanel/groups', title: 'Grupos', type: 'link' },
			{ path: '/adminpanel/integrations', title: 'Integraciones', type: 'link' },
			{ path: '/adminpanel/dashboard', title: 'Reportes de Acceso', type: 'link' },
		]
	},
];
