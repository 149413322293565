import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { HelpersServices } from '../helpers/helpers.services';

@Directive({
	// tslint:disable-next-line: directive-selector
	selector: '[hasPermission]'
})

export class HasPermissionDirective implements OnInit {

	constructor(
		private el: ElementRef,
		private helpers: HelpersServices,
	) { }

	@Input() hasPermission: string;

	ngOnInit() {
		if (!this.helpers.hasPermission(this.hasPermission)) {
			this.el.nativeElement.remove();
		}
	}

}
