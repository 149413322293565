import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SearchOptions } from 'src/app/interfaces/search.interface';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
	@Input() options: SearchOptions = { };
	@Input() tooltips: SearchOptions = { };
	@Output() searching = new EventEmitter<string>();
	@Output() typing = new EventEmitter<string>();
	search = '';
	optionsDefault: SearchOptions = {
		placeholder: 'app.search',
		icon: 'fa-search',
		tooltip: 'Buscar',
		showTitle: true,
		useSplitBy: false,
	};

	constructor() { }

	ngOnInit() {
		this.options = { ...this.optionsDefault, ...this.options };
	}

	ngOnChanges(changes: SimpleChanges) {
		const { options } = changes;

		if (options && options.currentValue !== undefined) {
			this.ngOnInit();
		}
	}

	onTyping() {
		this.typing.emit(this.search);
	}

	onSearch() {
		this.searching.emit(this.search);
		this.search = '';
	}

}
