import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output
} from '@angular/core';

@Directive({
	// tslint:disable-next-line: directive-selector
	selector: '[omaInfiniteScroll]',
})
export class OmaInfiniteScrollDirective {
	@Input() scrollDownDistance = 1;
	@Input() scrollUpDistance = 1;
	@Input() timeBetweenEvents = 500;
	@Input() isLoading = false;
	@Output() scrolledDown: EventEmitter<any> = new EventEmitter<any>();
	@Output() scrolledUp: EventEmitter<any> = new EventEmitter<any>();

	private lastDownPosition: number;
	private currentDownPosition: number;
	private lastUpPosition: number;
	private currentUpPosition: number;
	private waiting: boolean;

	@HostListener('scroll', ['$event'])
	onScrollHost(e: Event): void {
		const element = (e.target as Element);

		this.lastDownPosition = this.currentDownPosition;
		this.lastUpPosition = this.currentUpPosition;
		this.currentDownPosition = (element.scrollTop + element.clientHeight);
		this.currentUpPosition = element.scrollTop;

		const scrollDownPercentage = element.scrollHeight * (this.scrollDownDistance / 10);
		const limitDown = (element.scrollHeight - scrollDownPercentage);
		const limitUp = element.scrollHeight * (this.scrollUpDistance / 10);

		if (this.currentDownPosition >= limitDown && this.lastDownPosition <= this.currentDownPosition) {
			if (!this.waiting && !this.isLoading) {
				this.waiting = true;
				this.scrolledDown.emit();

				setTimeout(() => {
					this.waiting = false;
				}, this.timeBetweenEvents);
			}
		} else if (this.currentUpPosition <= limitUp && this.lastUpPosition >= this.currentUpPosition) {
			if (!this.waiting && !this.isLoading) {
				this.waiting = true;
				this.scrolledUp.emit();

				setTimeout(() => {
					this.waiting = false;
				}, this.timeBetweenEvents);
			}
		}
	}

	constructor() {
		this.lastDownPosition = 0;
		this.currentDownPosition = 0;
		this.lastUpPosition = 0;
		this.currentUpPosition = 0;
		this.waiting = false;
	}
}
