import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { FilterConcept, GetProjectListParams } from '../interfaces/projects.interfaces';
import { UserAuth, UserPermission } from '../interfaces/users.interface';
import * as moment from 'moment';

export interface NotifyType {
	type?: 'default' | 'danger' | 'success' | 'warning' | 'info';
	title?: string;
	message?: string | any;
	position?: 'top-center' | 'top-left' | 'top-right' | 'bottom-center' | 'bottom-left' | 'bottom-right';
	options?: NotifyOptionType;
}

interface NotifyOptionType {
	timeOut?: number;
	closeButton?: boolean;
	enableHtml?: boolean;
	tapToDismiss?: boolean;
	titleClass?: string;
	progressBar?: boolean;
	positionClass?: string;
	toastClass?: string;
}

export interface DecodedUserResponse {
	status: boolean;
	token: string;
	user: UserAuth;
}

@Injectable({
	providedIn: 'root'
})
export class HelpersServices {
	api: string;
	calling: EventEmitter<any> = new EventEmitter<any>();
	setInterval: any;
	intervalSubscription: any;
	errorMessage: NotifyType = {
		title: 'Error',
		message: 'Ocurrió un error inesperado!',
		type: 'danger',
	};

	constructor(
		private router: Router,
		private http: HttpClient,
		private location: Location,
		private toastr: ToastrService,
	) {
		this.api = environment.baseUrl;
	}

	public getDecodedUser(data?: string)  {
		data = data || localStorage.getItem('userAuth');

		if (data) {
			return JSON.parse(decodeURIComponent(atob(data)
				.split('')
				.map((c) => ('%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)))
				.join('')));
		} else {
			return false;
		}
	}

	public updateUserInfo(): void {
		this.resources({
			url: `${this.api}/users/info`,
			method: 'post',
		}).subscribe(({ status, message = '', data }) => {
			if (status) {
				localStorage.setItem('userAuth', data);
			} else {
				this.notify({
					type: 'danger',
					title: 'Error inesperado!',
					message,
				});
			}
		});
	}

	public getUrlList() {
		const parseUrl = (parent: string, config: any[], routeList: any[]) => {
			for (const route of config) {

				routeList.push(`${parent}/${route.path}`);

				if (route.children) {
					const currentPath = route.path ? parent + '/' + route.path : parent;
					routeList = parseUrl(currentPath, route.children, routeList);
				}
				if (route._loadedConfig) {
					const currentPath = route.path ? parent + '/' + route.path : parent;
					routeList = parseUrl(currentPath, route._loadedConfig.routes, routeList);
				}
			}
			return routeList;
		};

		return parseUrl('', this.router.config, []);
	}

	public resources<T = any>({ method = 'get', url = '', params = { }, responseType = null }, data?: any) {
		this.calling.emit();
		localStorage.setItem('loginTime', Date.now().toString());

		const met = method.toLowerCase();
		const { url: uri, afterParams: remaining } = this.replaceExplicitParams(params, url);
		const headers = new HttpHeaders({
			'x-access-token': localStorage.getItem('userAuth'),
		});
		const options = { headers, responseType };

		url = this.replaceUnexplicitParams(remaining, uri);

		if (responseType === null) {
			delete options.responseType;
		}

		if (met === 'get') {
			return this.http[met](url, options);
		} else {
			return this.http[met](url, data, options);
		}
	}

	public replaceExplicitParams(params: object, url: string) {
		const afterParams = { };

		for (const p in params) {
			if (params.hasOwnProperty(p) && params[p] !== undefined && params[p] !== null) {
				const search = new RegExp(`:${p}`, 'g');
				if (url.match(search)) {
					url = url.replace(`:${p}`, params[p]);
				} else {
					afterParams[p] = params[p];
				}
			}
		}

		return { url, afterParams };
	}

	public replaceUnexplicitParams(params: object, url: string) {
		const add = [];

		for (const p in params) {
			if (params.hasOwnProperty(p) && params[p] !== undefined && params[p] !== null) {
				if (Array.isArray(params[p])) {
					for (const par of params[p]) {
						if (par !== undefined && par !== null) {
							add.push(`${p}=${par}`);
						}
					}
				} else {
					add.push(`${p}=${params[p]}`);
				}
			}
		}

		url += (add.length) ? `?${add.join('&')}` : '';

		return url;
	}

	public notify({ type = 'default', title, message, position = 'top-center', options }: NotifyType): void {
		const icons = {
			default: 'fas fa-bell',
			danger: 'fas fa-times-circle',
			warning: 'fas fa-exclamation-triangle',
			success: 'fas fa-check-circle',
			info: 'fas fa-info-circle',
		};

		const config = Object.assign({
			timeOut: 8000,
			closeButton: true,
			enableHtml: true,
			tapToDismiss: false,
			titleClass: 'alert-title',
			progressBar: true,
			positionClass: `toast-${position}`,
			toastClass: `ngx-toastr alert alert-dismissible alert-${type} alert-notify`
		}, options);

		this.toastr.show(`
			<span class="alert-icon ${icons[type]}" data-notify="icon"></span>
			<div class="alert-text">
			<span class="alert-title" data-notify="title">${title || 'Notificación'}</span>
			<span data-notify="message">${message}</span>
			</div>`,
			'',
			config,
		);
	}

	public updateUrlParameters(url: string, attributes: GetProjectListParams = { }): void {
		const params = [];

		for (const name in attributes) {
			if (attributes.hasOwnProperty(name)) {
				const value = attributes[name];

				if (value) {
					params.push(`${name}=${value}`);
				}
			}
		}
		const parameters = params.length ? `?${params.join('&')}` : '';

		this.location.go(url + parameters);
	}

	public download({ url, params = { }, filename = 'download.csv', callback = ((s, e?) => { }), method = 'GET' }) {
		if (url) {
			const request = {
				url,
				method,
				responseType: 'blob',
				params,
			};

			this.resources(request).subscribe(response => {
				const a = document.createElement('a');

				if (response.type == 'application/json') {
					callback(false);

					this.notify({
						type: 'warning',
						title: 'Aviso!',
						message: 'Ocurrio un problema al descargar archivo. Favor comuniquese con el administrador para resolver el problema.',
						options: { timeOut: 3000 },
					});

					return false;
				}

				a.href = window.URL.createObjectURL(response);
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				callback(true);
			}, error => {
				callback(false, error);
				this.notify({
					type: 'warning',
					title: 'Aviso!',
					message: 'Ocurrio un problema al descargar archivo. Favor comuniquese con el administrador para resolver el problema.',
					options: { timeOut: 3000 },
				});
			});
		}
	}

	public handleErrors(error?: NotifyType): void {
		const title = (error && error.title) ? error.title : 'Error';
		let message;

		if (error && error.message) {
			message = (error.message && error.message.includes('Http failure')) ? 'Lo sentimos, no hemos podido conectar con el servidor' : error.message;
		}

		const userAuth = this.getDecodedUser() as DecodedUserResponse;

		if (userAuth && userAuth.user && userAuth.user.userId) {
			this.notify({
				type: 'danger',
				title,
				message,
				options: { timeOut: 3000 },
			});
		}
	}

	private getNameInitials(fullname: string): string {
		const [name, lastname, thirdname] = fullname.split(' ');
		const lastInitial = (thirdname ? thirdname[0] : (lastname ? lastname[0] : name[1]));

		return (name[0] + lastInitial).toUpperCase();
	}

	public getGravatarImage(fullname: string): string {
		const initials = this.getNameInitials(fullname || '');
		const color = Math.floor((Math.random() * 6) + 1);
		const img = `${initials}-${color}.png`;

		return `https://i2.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${img}?ssl=1`;
	}

	public logout(): void {
		const userAuth = this.getDecodedUser() as DecodedUserResponse;

		if (userAuth && userAuth.user && userAuth.user.userId) {
			const sessionId = localStorage.getItem('sessionId') || 0;
			const data = {
				userId: userAuth.user.userId,
				sessionId,
			};

			this.resources({
				url: `${this.api}/users/logout`,
				method: 'post',
			}, data)
				.subscribe(
					response => {
						if (response.status) {
							localStorage.removeItem('userAuth');
							localStorage.removeItem('loginTime');
							localStorage.removeItem('sessionId');

							try {
								this.intervalSubscription.unsubscribe();
							} catch (error) {
								console.log('error->', error);
							}
							this.router.navigate(['/login']);
						}
					},
					error => this.handleErrors(error)
				);
		} else {
			this.router.navigate(['/login']);
		}
	}

	public checkSessionTime() {
		this.setInterval = interval(10000);

		const { loginSessionTime = 1, sessionWarningTime = 0.5 } = environment;
		const sessionTime = loginSessionTime * 60 * 1000;
		const warningTime = sessionWarningTime * 60 * 1000;
		const lastLogin = localStorage.getItem('loginTime');

		if (typeof lastLogin !== 'undefined') {
			let showingWarning = false;

			this.intervalSubscription = this.setInterval.subscribe(() => {
				const loginTime = parseInt(lastLogin, 10);
				const totalSessionTime = loginTime + sessionTime;
				const currentTime = Date.now();

				if (totalSessionTime <= currentTime) {
					this.logout();
					this.intervalSubscription.unsubscribe();

					return;
				}

				if (((totalSessionTime - warningTime) <= currentTime) && !showingWarning) {
					showingWarning = true;
					let timerInterval;

					Swal.fire({
						title: 'Su sesión esta por terminar',
						html: 'Por inactividad su sesión terminará en <b></b> segundos, desea mantener la sesión?',
						icon: 'warning',
						timer: (warningTime - 1),
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						onBeforeOpen: () => {
							timerInterval = setInterval(() => {
								const content = Swal.getContent();

								if (content) {
									const b = content.querySelector('b');
									if (b) {
										b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0).toString();
									}
								}
							}, 100);
						},
						cancelButtonColor: '#d33',
						confirmButtonText: 'Si, Mantener!',
						cancelButtonText: 'No, Cerrar sesión!'
					}).then(response => {
						if (response.value) {
							this.intervalSubscription.unsubscribe();
							localStorage.setItem('loginTime', currentTime.toString());
							this.checkSessionTime();
						} else {
							this.logout();
						}
					});
				}
			});
		}
	}

	getUserPermissions(): UserPermission {
		const { user: userAuth } = this.getDecodedUser() as DecodedUserResponse;
		const { permissions = [] } = (userAuth || { });

		return permissions[0];
	}

	public hasPermission(permissionName: string): boolean {
		const permissions = this.getUserPermissions();

		return permissions !== undefined && permissions[permissionName] !== undefined;
	}

	public toast(options: SweetAlertOptions): Promise<SweetAlertResult> {
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			onOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			},
		});

		return Toast.fire(options);
	}

	public closureProjectedPermission(permission: string, closureProjected: string, isClosed: boolean, solotId: number): boolean {
		const { user } = this.getDecodedUser() as DecodedUserResponse;

		if (
			(this.hasPermission(permission) &&
				closureProjected != 'Abortada' &&
				closureProjected != 'Terminada' &&
				!isClosed)
			||
			(user && user.roles.some(arr => arr === 'PMO'))
		) {
			return true;
		}

		return false;
	}

	public prepareArrayToSendParameter(items: FilterConcept[], attr: string = 'id_code') {
		return items.length ? items.map(a => String('\'' + a[attr] + '\'')).join(',') : null;
	}

	public prepareArrayToSendParameterId(items: FilterConcept[], attr: string = 'id') {
		return items.length ? items.map(a => String('\'' + a[attr] + '\'')).join(',') : null;
	}

	public prepareArrayToSendParameterString(items: FilterConcept[], attr: string = 'ds_name') {
		return items.length ? items.map(a => String('\'' + a[attr] + '\'')).join(',') : null;
	}

	public getItemSelected(data: any[], id = '', attr = 'id_code') {
		const selectedInParams = id.replace(/\'/g, '').split(',');

		return data.filter(item => selectedInParams.some(idSelected => idSelected == item[attr]));
	}
	public getItemSelectedName(data: any[], id = '', attr = 'ds_name') {
		const selectedInParams = id.replace(/\'/g, '').split(',');

		return data.filter(item => selectedInParams.some(idSelected => idSelected == item[attr]));
	}

	public guid(segmentCounts: number = 2): string {
		const segments: string[] = [];

		segments.push(s4() + s4());
		function s4(): string {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}
		for (let i = 0; i < segmentCounts; i++) {
			segments.push(s4());
		}
		segments.push(s4() + s4() + s4());

		return segments.join('-');
	}

	public formatDateAttribute(date: string, format: string = 'DD/MM/YYYY'): string {
		const formattedDate = date ? date.substr(0, 19) : false;

		return formattedDate ? moment(formattedDate).format(format) : null;
	}

	public formatNumber(value: number): string {
		return new Intl.NumberFormat('de-DE').format(value || 0);
	}
}
