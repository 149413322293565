import { Component, Input, OnInit } from '@angular/core';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';
import { HelpersServices } from '../../helpers/helpers.services';

@Component({
	selector: 'app-solot-card',
	templateUrl: './solot-card.component.html',
	styleUrls: ['./solot-card.component.scss']
})
export class SolotCardComponent implements OnInit {
	@Input() solot: any = { };
	@Input() projectedClosingMonthList: FilterConcept[] = [];
	@Input() closureChangeCauseList: FilterConcept[] = [];
	savingTableChanges = false;

	constructor(
		private helpers: HelpersServices
	) { }

	ngOnInit() {
	}

}
