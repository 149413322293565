import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { Subscription } from 'rxjs';
import { HelpersServices } from 'src/app/helpers/helpers.services';
import { Project } from 'src/app/interfaces/projects.interfaces';
import { Paginator } from '../../interfaces/paginator.interface';
import swal from 'sweetalert2';

import { ProjectInputsList } from '../../interfaces/filters-inputs.interface';
import { AdminpanelService } from 'src/app/pages/adminpanel/adminpanel.service';
import { ProjectsServices } from 'src/app/pages/projects/projects.services';
import { Response } from 'src/app/interfaces/api-response.interface';
import { FilterConcept } from '../../interfaces/projects.interfaces';
import { UserEntry } from 'src/app/interfaces/users.interface';

@Component({
	selector: 'app-project-card-container',
	templateUrl: './project-card-container.component.html',
	styleUrls: ['./project-card-container.component.scss']
})

export class ProjectCardContainerComponent implements OnInit, OnDestroy {
	@Input() projects: Project[] = [];
	@Input() filtersListData: ProjectInputsList;
	@Input() paginator: Paginator;
	@Input() isLoading: boolean;
	@Input() allSearchParams: { [propName: string]: string | number } = { };
	@Input() expanded: boolean;

	@Output() loadMore = new EventEmitter<Paginator>();
	@Output() submit = new EventEmitter<Project>();

	savingTableChanges = false;
	saveProjectSubscription: Subscription;

	constructor(
		private helpers: HelpersServices,
		private api: ProjectsServices,
		private adminApi: AdminpanelService,
	) { }

	ngOnInit() {
		this.getUserList();
		this.filtersListData = {
			...this.filtersListData,
			userList: [],
		};
	}

	ngOnDestroy() {
		if (this.saveProjectSubscription) {
			this.saveProjectSubscription.unsubscribe();
		}
	}

	onScrollDown() {
		if (this.isNextPageAvailable()) {
			this.paginator.page++;
			this.loadMore.emit(this.paginator);
		}
	}

	onScrollUp() {
		if (this.paginator && this.paginator.page > 0) {
			console.log('Scrolling up');
			// do something!
		}
	}

	getUserList(): void {
		this.adminApi.getUsers().subscribe(
			(response: Response<UserEntry[]>) => {
				if (response.status) {
					response.data = response.data || [];
					this.filtersListData.userList = response.data.map(user => ({
						id_code: user.userId,
						ds_name: user.fullname,
					} as FilterConcept));
				}
			},
			(error) => {
				this.isLoading = false;
				swal.fire({
					title: 'Error',
					text: 'Lo sentimos, no hemos podido conectar con el servidor',
					icon: 'error',
				});
			}
		);
	}

	private isNextPageAvailable(): boolean {
		return (this.paginator && (this.paginator.page ? this.paginator.page : 1) < this.getTotalPages());
	}

	private getTotalPages(): number {
		return (this.paginator.size > 0)
			? Math.ceil(this.paginator.total / this.paginator.size)
			: 0;
	}

	private onProjectSave(project: Project) {
		this.submit.emit(project);
	}
}
