import { Injectable } from '@angular/core';
import { HelpersServices } from '../helpers/helpers.services';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ComponentsServices {
	api: string;
	headers: any;

	constructor(private Helpers: HelpersServices) {
		this.api = environment.baseUrl;
	}

	public getLastUpdate(): Observable<any> {
		return this.Helpers.resources({
			url: `${this.api}/commons/lastUpdate`,
			method: 'get',
		});
	}

}
