import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES, CONFIG } from './sidebar.menu';
import { HelpersServices } from '../../helpers/helpers.services';
import { RouteInfo } from '../../interfaces/menu.interfaces';
import { environment } from 'src/environments/environment';
const misc: any = {
	sidebar_mini_active: true
};

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	public menuItems: RouteInfo[];
	public configMenu: RouteInfo[];
	public isCollapsed = true;

	constructor(
		private router: Router,
		private helpers: HelpersServices
	) { }

	ngOnInit() {
		const userAuth = this.helpers.getDecodedUser();

		if (userAuth) {
			this.menuItems = this.getMenuByPermissions(userAuth, ROUTES);
			this.configMenu = this.getMenuByPermissions(userAuth, CONFIG);
		}
		this.minimizeSidebar();
		setTimeout(() => {
			this.onMouseLeaveSidenav();
		}, 400);
	}

	getMenuByPermissions(userAuth: any, menu: RouteInfo[] = []): RouteInfo[] {
		return menu.filter(menuItem => {
			const { production } = environment;

			if (typeof menuItem.children !== 'undefined') {
				const children = this.getMenuByPermissions(userAuth, menuItem.children);

				return menuItem.children = children;
			} else if (userAuth.user.url.includes(menuItem.path)) {
				return true;
			} else if (production === false) {
				return true;
			} else {
				return false;
			}
		});
	}

	onMouseEnterSidenav() {
		if (!document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.add('g-sidenav-show');
		}
	}

	onMouseLeaveSidenav() {
		if (!document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-show');
		}
	}

	minimizeSidebar() {
		const sidenavToggler = document.getElementsByClassName(
			'sidenav-toggler'
		)[0];
		const body = document.getElementsByTagName('body')[0];
		if (body.classList.contains('g-sidenav-pinned')) {
			misc.sidebar_mini_active = true;
		} else {
			misc.sidebar_mini_active = false;
		}
		if (misc.sidebar_mini_active === true) {
			body.classList.remove('g-sidenav-pinned');
			body.classList.add('g-sidenav-hidden');
			sidenavToggler.classList.remove('active');
			misc.sidebar_mini_active = false;
		} else {
			body.classList.add('g-sidenav-pinned');
			body.classList.remove('g-sidenav-hidden');
			sidenavToggler.classList.add('active');
			misc.sidebar_mini_active = true;
		}
	}
}
