import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterConcept } from 'src/app/interfaces/projects.interfaces';

@Component({
	selector: 'app-selected-filters',
	templateUrl: './selected-filters.component.html',
	styleUrls: ['./selected-filters.component.scss']
})
export class SelectedFiltersComponent implements OnInit {
	@Input() showTitle = true;
	@Input() filters: FilterConcept[] = [];
	@Output() filtersChange = new EventEmitter<FilterConcept[]>();

	isOpen = true;

	constructor() {
	}

	ngOnInit() {
	}

	onRemove(index: number) {
		this.filters.splice(index, 1);
		this.filtersChange.emit(this.filters);
	}

}
